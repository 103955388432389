.t-border-inputs {
  background: $color-gray;
  min-height: var(--height-misc);
  height: var(--height-misc);
  padding: var(--border-width);
  border-radius: var(--border-radius);
  position: relative; //TODO comprobar que no pase nada colateral
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.auto-height {
    height: max-content;
    min-height: auto;
    // background: none;
  }
  &.t-border-inputs--border {
    box-shadow: inset 0 4px 10px rgba($color-black, 0.25)
  }
  &.t-border-inputs--black{
    background: darken($color-gray, 20);
  }
  &.is-focused {
    // background: $color-purple;
    padding: calc(var(--border-width));
  }

  &.is-disabled {
    background: $color-light-purple;
  }
  &.has-error{
    background: rgba($color-red, 0.2);
  }
}
