/*=============================================
=               KEYFRAMES                     =
=============================================*/

@keyframes flicker {
  to {
    background: rgba($color-purple, 0.8);
  }
}
@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-28px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-28px) rotate(-360deg);
  }
}
@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: var(--color-purple);
  }
}
@keyframes opa {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*=====  End of KEYFRAMES              ======*/
