.o-photo-specs {
  border-radius: var(--border-radius);
  box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
  padding: 10px 20px 0;
  &.is-empty {
    filter: grayscale(70%);
    opacity: 0.7;
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid var(--color-gray);
    color: var(--color-purple);
    cursor: default;
    display: flex;
    font-size: 2rem;
    font-weight: 500;
    justify-content: space-between;
    padding: 10px 0;
    .a-button {
      height: 32px;
      min-height: 32px;
    }
    &.no-data {
      padding: 10px 0 15px;
    }
  }
  &__content {
    height: 85px;
    padding: 20px 0 0;
    .t-flex-component {
      gap: 45px;
    }
    .is-empty & {
      height: 0;
    }
  }
}
