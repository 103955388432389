.m-dimensions-card {
  &__content {
    margin: 10px 0;
    height: calc(82% - 20px);
    border-radius: var(--border-radius);
    background: rgba($color-purple, 0.2);
    width: 100%;
    padding: 10px 10px 10px 10px;
    display: flex;
    .property-input {
      position: relative;
      &__times {
        font-size: 1.1rem;
        color: var(--color-dark-gray);
        margin-right: -10px;
      }
      &__text {
        display: block;
        bottom: 93%;
        position: absolute;
        color: var(--color-purple);
        font-size: 1rem;
        left: 50%;
        transform: translateX(-50%);
      }
      &__unit {
        font-size: 1.1rem;
        color: var(--color-dark-gray);
        position: absolute;
        top: 60%;
        right: 10px;
        transform: translateY(-50%);
      }
      textarea {
        display: block;
        min-width: 40px;
        max-width: 80px;
        text-align: right;
        max-height: 23px;
        color: var(--color-dark-gray);
        padding: {
          right: 29px;
          left: 10px;
          top: 0;
          bottom: 0;
        }
        font-size: 1.5rem;
        line-height: auto;

        &:read-only {
          background: transparent;
          border: none;
          cursor: default;
        }
      }
      &.--no-padding {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        textarea {
          max-width: 105px;
          padding: 0px 3px 0px 0px;
        }
      }
      
      &.--multi-input {
        display: flex;
        justify-content: space-between;
        width: 60%;
        > div {
          text-align: center;
        }
        textarea {
          padding: 0 6px;
        };
        p {
          color: $color-purple;
          font-size: 1rem;
        }
      }

      .--nobg-checkbox {
        text-align: center;
        p {
          color: $color-purple;
          font-size: 1rem;
        }
      }
      
    }
    &-data {
      height: 100%;
      width: 55%;
      .m-select {
        height: 27px;
        &__button {
          height: 27px;
        }
      }
    }
    &-square {
      min-height: 180px;
      height: 100%;
      width: 45%;
      max-width: 600px;
      max-height: 600px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
  &__square {
    position: absolute;
    display: block;
    min-width: 80px;
    min-height: 80px;
    height: var(--height);
    width: var(--width);
    transform: scale(var(--scale));
    transition: 0.1s;
    background: var(--bg-color);
    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      right: 0;
      left: 0;
      height: 1px;
      background: darken($color-gray, 20);
      transition: 0.1s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: -5px;
      width: 1px;
      background: darken($color-gray, 20);
      transition: 0.1s;
    }
    &-height,
    &-width {
      display: block;
      position: absolute;
      color: var(--color-dark-gray);
      font-size: 1.2rem;
      width: max-content;
      cursor: default;
    }
    &-height {
      white-space: nowrap;
      width: 0;
      transform: rotate(90deg);
      top: 0;
      right: -14px;
    }
    &-width {
      transform: translate(-50%);
      top: 105%;
      left: 50%;
    }
  }

  &__content-margin {    
    border: 2px dotted #444444;
    background-color: transparent;
    left: calc(var(--l3));
    min-height: calc(var(--height) - (var(--l0) + var(--l2)));
    min-width: calc(var(--width) - (var(--l1) + var(--l3)));
    height: calc(var(--height) - (var(--l0) + var(--l2)));
    width: calc(var(--width) - (var(--l1) + var(--l3)));
    position: absolute;
    top: var(--l0);
    transition: 0.1s;
  }
}
