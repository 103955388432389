.o-sales-channel-card {
  border-radius: $border-radius;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  min-width: 620px;
  width: 650px;
  overflow: hidden;
  box-shadow: $shadow;
  
  &__dimensions {
    min-width: 63%;
    width: 63%;
    display: grid;
    grid-template-areas: 
      "a a b"
      "c d e"
      "c f g"
      "c h i";
    grid-template-columns: 40% 30% 30%;
    grid-template-rows: 55px 55px 55px 55px;
    .--name {
      align-items: flex-end;
      color: $color-purple;
      display: flex;
      font-size: 2rem;
      grid-area: a;
      padding: 10px var(--padding);
    }
    .--category {
      align-items: flex-end;
      color: $color-purple;
      display: flex;
      font-size: 1.6rem;
      grid-area: b;
      padding: 10px 0px;
    }
    .--preview {
      grid-area: c;
      padding-top: 20px;
      display: flex;
      justify-content: center;
    }
    .--width {
      grid-area: d;
    }
    .--height {
      grid-area: e;
    }
    .--resolution {
      grid-area: f;
      font-weight: 600;
    }
    .--size {
      grid-area: g;
    }
    .--extension {
      grid-area: h;
      .--data {text-transform: uppercase;}
    }
    .--config {
      grid-area: i;
      color: $color-purple;
      display: flex;
      align-items: center;
      button {
        svg { font-size: 1.5rem;}
        span { font-size: 1.3rem !important;}
      }
    }
    .--label{
      color: $color-dark-gray;
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
    .--data {
      color: $color-dark-gray;
      font-size: 1.4rem;
    }
  }

  &__attributes {
    background-color: $color-light-purple;
    display: grid;
    min-width: 37%;
    grid-template-areas: 
      "a a a"
      "b c d"
      "e f f"
      "g i i";
    grid-template-columns: 44% 28% 28%;
    grid-template-rows: 55px 55px 55px 55px;
    padding: 0px var(--padding);
    width: 37%;

    .--category {
      align-items: flex-end;
      color: $color-purple;
      display: flex;
      justify-items: left;
      font-size: 1.6rem;
      grid-area: a;
      padding: 10px 0px;
    }
    .--no-columns {
      grid-area: b;
    }
    .--download {
      align-items: center;
      display: flex;
      grid-area: g;
      justify-items: flex-end;
      button span { font-size: 1.2rem; }
    }

    .--origin {
      grid-area: e;
    }
    .--unique {
      grid-area: f;
    }

    .--lang {
      grid-area: g;
      .--data { text-transform: uppercase;}
    }

    .--label{
      color: $color-dark-gray;
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
    .--data {
      color: $color-dark-gray;
      font-size: 1.4rem;
      font-weight: 700;
    }

    .--config {
      // grid-column-start: h;
      // grid-column-end: i;
      grid-area: i;
      color: $color-purple;
      display: flex;
      align-items: center;
      button {
        svg { font-size: 1.5rem;}
        span { font-size: 1.3rem !important;}
      }
    }

    .--empty-data {
      align-items: center;
      color: $color-dark-gray;
      display: flex;
      grid-column-start: b;
      grid-column-end: f;
      grid-row-start: b;
      grid-row-end: e;
    }
  }

}
