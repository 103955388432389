.m-date-task {
  align-items: center;
  background: var(--color-white);
  border-radius: 30px;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 0 10px;
  font-size: 1.4rem;
  color: var(--color-purple);
  font-weight: 500;
  &__point {
    background: var(--color-purple);
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .t-flex-component {
    gap: 6px;
  }
  &.is-purple{
    background: rgba($color-purple, 0.07);
  }
}
