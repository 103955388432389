.t-columns {
  display: flex;
  height: 100%;

  &__left {
    height: 100%;
    /*    padding-right: var(--padding); */
    width: var(--porcent);
    transition: .15s;
  }
  &__right {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    /* padding-left: 10px; */
    width: calc(100% - var(--porcent));
    transition: .15s;
    &-menu {
      position: absolute;
      bottom: 0;
      right: 0;
      min-width: 100%;
    }
  }
  &.is-horizontal {
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 20px;
    .t-columns__left {
      width: 95%;
      height: var(--porcent);
      order: 2;
    }
    .t-columns__right {
      order: 1;
      width: 95%;
      height: calc(100% - var(--porcent));;
    }
  }
}
