.o-generate-layer-images-modal {
  $bg-color: $color-light-purple + '70';
  clear: both;
  @include modal-styles;
  min-height: 250px;
  max-width: 850px;
  min-width: 650px;
  width: auto;

  &__carousell {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 140px;
    gap: 10px;
    justify-content: center;
    padding: 20px 0px;
    
    .--thumbnail {
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      max-height: 90px;
      min-height: 90px;
      max-width: 90px;
      min-width: 90px;
    }

    .--selected {
      border: $color-purple solid 1px;
      border-radius: $border-radius;
    }
  }

  &__processing {
    align-items: center;
    background-color: $color-light-purple;
    border-radius: 10px;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 65px 0px;
    > div {
      color: $color-purple;
      width: 40%;
    }
    &-data {
      align-items: center;
      display: flex;
      font-size: 1.9rem;
    }
  }

  &__selector {
    padding: 30px 0px;
    .--list-item-opt {
      align-items: center;
      display: flex;
      gap: 5px;
    }
    .a-badge--border-purple {
      min-width: 60px !important;
    }
  }

  &__preview {
    display: flex;
    flex-direction: row;
    gap: 80px;
    height: 100%;
    justify-content: center;
    > div {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px dotted $color-purple;
      min-width: 120px;
      min-height: 120px;
    }

  }      
  &__legend {
    background-color: #{$bg-color};
    border-radius: $border-radius;
    color: $color-purple;
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    gap: 10px;
    margin-top: 20px;
    max-width: 650px;
    padding: 20px 10px;
  }

  .--attribute-load {
    align-items: center;
    background-color: #{$bg-color};
    border-radius: 10px;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 65px 0px;
    div {
      color: $color-purple;
      width: 40%;
    }
    .--label {
      align-items: center;
      display: flex;
      font-size: 1.9rem;
    }
  }
  .--image-data {
    display: flex;
    color: $color-purple;
    flex-direction: row;
    width: 800px;
    height: 300px;
    > div:first-child { width: 40% }
    > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 60%;
      > div:first-child {
        font-size: 1.8rem; 
        font-weight: 600;
      }
      > div:last-child {
        height: 300px;
        max-height: 300px;
      }
      .ScrollbarsCustom-Content {
        display: flex;
        flex-direction: column;
        gap: 18px;
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }

    .--attribute-label {
      display: flex;
      gap: 5px;
      justify-content: space-between;

      > div:first-child {
        align-items: center;
        display: flex;
        gap: 5px;
        width: calc(100% - 65px);
        p {
          max-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }


  &__ai-config {
    align-items: stretch;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    .--preview {
      display: flex;
      justify-content: center;
      div {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        border: $color-purple 1px dotted;
        height: 160px;
        width: 160px;
      }
    }
    > div {
      width: 50%;
    }
  }

  @include modal-buttons;
}
