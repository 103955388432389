.m-counter {
  background: var(--color-gray);
  padding: 0 15px 0 5px 0px;
  display: flex;
  width: 30%;
  height: 43px;
  border-radius: 10px;
  width: 110px;
  &__input {
    text-align: center;
    color: var(--color-dark-gray);
    font-weight: 600;
    font-size: 2rem;
    &:focus {
      color: var(--color-purple);
      transition: 0.3s;
      overflow: hidden;
    }
  }
  &__buttons {
    margin: auto;
  }
  &__button {
    margin: 1px 0;
    svg {
      transition: 0.3s;
    }
    &:hover {
      svg {
        transform: scale(1.2);
        color: var(--color-purple);
      }
    }
  }
}
