.v-users-panel {
  height: 100%;

  &__content {
    height: 100%;
    min-height: 100%;
    padding: 1px var(--padding) calc(var(--padding) /2) ;
  }

  &__table {
    height: 100%;
    min-height: 100%;

  }
  &__table-body {
    min-height: calc(100% - 50px);
    height: calc(100% - 50px);
  }
}
