.o-complete-upload {
  background: var(--color-gray);
  border-radius: var(--border-radius);
  height: 100%;
  margin-top: 15px;
  padding: 12px;
  position: relative;
  width: 100%;
  animation: opa 1s forwards;
  &__header {
    bottom: 105%;
    font-size: 1.4rem;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .a-button {
      max-width: 20%;
      span {
        font-size: 15px;
      }
    }
  }
  &__file {
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: var(--color-dark-gray);
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    &-item {
      display: grid;
      margin-bottom: 10px;
      grid-template-columns: 1fr 4fr 1fr;
      align-items: center;
      &--red {
        * {
          color: var(--color-red) !important;
        }
      }
      &.header {
        .o-complete-upload__content-text {
          padding: 0 !important;
        }
        span {
          font-size: 1.5rem;
          font-weight: 500;
          color: var(--color-purple);
          &:last-child {
            color: black;
            font-size: 1.4rem;
            font-weight: 400;
            width: 60px;
            text-align: center;
          }
        }
      }
      input {
        height: 20px;
      }
    }
    &-qty {
      color: var(--color-purple);
      font-size: 2.5rem;
      justify-self: center;
    }
    &-text {
      color: var(--color-dark-gray);
      font-size: 1.7rem;
      padding-left: 10px;
      white-space: nowrap;
    }
  }
}
