.t-details{
    background: var(--color-white);
    border-radius: var(--border-radius);
    height: calc(100% - var(--height-misc));
    padding: calc(var(--padding) + 45px) var(--padding) 20px 0;
    padding: calc(var(--padding) + 45px) 20px 30px;
    position: relative;
    top: calc((var(--height-misc) + 10px ) * -1);
    width: 100%;
    &__children{
        height: 100%;
    }
    .o-gallery{
        height: calc(100% - 12px);
    }
}
