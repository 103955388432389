.m-multiple-options{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__badges{
        padding-right: 20px;
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
        > * {
            margin: 8px;
        }
    }
    &__button{
        width: 300px;
    }
}
