.o-nbsk-editor {
  height: 100%;
  padding: 0;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 70px);
    flex-wrap: wrap;
    gap: 20px;
    min-height: calc(100% - 70px);
    width: 100%;
    canvas {
      border: 1px solid $color-purple,
    }
  }

  &__canvas-panel {
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $shadow;
    max-height: 100%;
    min-height: 100%;
    padding: 10px;
    width: calc(100% - 510px);
  }

  &__canvas {
    align-items: center;
    background-image: url("../../../_utils/images/transparency.jpeg");
    background-size: 120px;
    display: flex;
    justify-content: center;
    margin: auto;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
  }

  &__tools-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 490px;
  }

  .--tabs {
    color: $color-purple;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .--tab { 
      align-items: center;
      background-color: $color-light-purple;
      border-radius: 10px 10px 0 0;
      cursor: pointer;
      display: flex;
      gap: 5px;
      justify-content: center;
      padding: 5px 10px;
      width: 33%;
      &.selected {
        background-color: $color-purple;
        color: $color-white;
      }
    };
  }
  &__controls {
    min-width: 100%;
    min-height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    padding: 10px 15px;
  }

  &__general-tools, &__controls {
    background-color: $color-white;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: $shadow;
  }

  &__size-pos, &__text-props, &__form-styles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between; 
  }

  &__size-pos {
    .--width, .--height {
      width: 120px;
    }
    .--pos, .--rotate {
      width: 70px;
    }
    .--border-radius {
      width: 300px;
      > div {
        width: 65%;
      }
    } 
  }

  &__text-props {
    .--font-family, .--font-justify{ width: 190px; }
    .--font-decoration { width: 130px; }
    .--font-size { width: 85px; }
    .m-select {
      width: 100%;
    }
  }

  &__form-styles {
    .--color-auto, .--shape-color,
    .--form-color,.--border-color, .--shadow-color { width: 150px; }

    .--opacity, 
    .--border-width{ width: 130px}

    .--color-auto,
    .--border,
    .--shadow, .--shadow-offset, .--shadow-blur { width: 95px;}

    .--opacity .t-form-control__input-control {
      display: block;
      .rangeslider { margin: 10px 0;}
      .rangeslider__handle-tooltip {
        height:27px; width: 32px; font-size: 1.2rem;
        top: -40px;
        span { margin-top: 8px;}
      }
      .rangeslider__fill { background-color: $color-purple !important;}
      .rangeslider__handle {
        width: 20px; height: 20px;
        &::after {
          display: none;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px 0;
  }

  &__tab-container {
    height: 100%;
    max-height: 100%;
  }

  &__ai-section {
    display: flex;
    flex-direction: row;
    > div {
      align-items: center;
      background-size:contain;
      cursor: pointer;
      display: flex;
      height: 60px;
      justify-items: center;
      max-height: 60px;
      max-width: 60px;
      min-height: 60px;
      min-width: 60px;
      width: 60px;
    }
  }

  &__shapes-section, &__ai-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 5px;
    > * {
      background-color: $color-gray;
      border: 2px solid $color-purple;
      border-style: inset;
      cursor: pointer;
      max-height: 50px;
      max-width: 50px;
      min-height: 50px;
      min-width: 50px;
      transition: 0.5s;
      opacity: 0.6;
      &:hover { 
        opacity: 1;
        .--obj-add-btn, .--obj-prev-btn {
          opacity: 0.9;
        }    
      }
    }

    .--obj-add-btn { 
      &::after { content: '+'; }
    }
    .--obj-add-btn, .--obj-prev-btn { 
      align-items: center;
      background-color: $color-purple;
      border-radius: 5px;
      color: $color-white;
      display: flex;
      justify-content: center;
      min-height: 30px;
      min-width: 30px;
      opacity: 0;
      position: absolute;
      transition: 0.5s;
    }
    [class^='--obj-'] {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    
    .--obj-landscape-img, .--obj-portrait-img, .--obj-square-img,
    .--obj-landscape-inverse, .--obj-portrait-inverse, .--obj-square-inverse,
    .--obj-text, .--obj-rect, .--obj-circle {
      border-style: dashed;
    }
    
    .--obj-rect {
      border-style: solid;
    }
    .--obj-circle {
      border-radius: 50%;
      border-style: solid;
    }
    .--obj-text, .--obj-line {
      border: none !important;
      padding: 0px;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__associates {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    > div:nth-child(odd) { width: 60%;}
    > div:nth-child(even) {
      display: flex;
      flex-direction: row-reverse;
      gap: 5px;
      width: calc(40% - 10px);
    }
  }

  &__lib-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin: auto;
    padding: 8px 0 0 0;
    width: 100%;
    > div {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      align-items: center;
      border: 1px dotted $color-purple;
      cursor: pointer;
      display: flex;
      height: 100px;
      justify-content: center;
      width: 100px;
      &:hover {
        .--btn-add { opacity: .8; }  
      }
      
    }
  }
  .--btn-add {
    align-items: center;
    background-color: $color-purple;
    border-radius: 5px;
    color: $color-white;
    display: flex;
    height: 30px;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;
    width: 30px;
    &::after {
      content: "+"
    }
  }

  &__product-attr {
    .--allowed-attr {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 10px 0;
      .--attribute {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        padding: 0 10px 0 0;
        width: 100%;
        .--label {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
        }
        &:hover {
          background-color: $color-light-purple;
          cursor: pointer;
        }
        &:hover {
          .--btn-add {
            float: right;
            opacity: .8;
          }  
        }
      }
      .--key {
        border: 1px solid $color-purple;
        border-radius: 10px;
        padding: 2px;
        text-align: center;
        width: 90px;
      }
      
    }
  }
}

