.m-input-text {
  position: relative;
  cursor: default;
  order: 3;
  &.mid-width {
    grid-column: span 2;
  }
  &.max-width {
    grid-column: span 3;
  }
  &.read-only {
    order: 4;
  }
  &__textarea {
    background: white;
    border-radius: 10px;
    border: none;
    font-size: 1.6rem;
    letter-spacing: 0.3px;
    line-height: 2.3rem;
    text-align: justify;
    outline: none;
    padding: 5px;
    resize: none;
    width: 100%;
    &::selection {
      color: var(--color-white);
      background: var(--color-purple);
    }
    &:read-only {
      background: $color-gray;
      color: rgb(75, 75, 75);
      cursor: pointer;
      &::selection {
        background: var(--color-blue);
        color: var(--color-white);
      }
    }
    &:disabled {
      cursor: default;
    }
  }
  &__label {
    display: block;
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 3px;
  }
  &__buttons {
    align-items: center;
    display: flex;
    position: absolute;
    right: 10px;
    top: 105%;
    z-index: 999;
    &-button {
      &:first-child {
        margin-right: 6px;
        color: var(--color-red);
        .t-flex-component {
          svg {
            font-size: 17px;
          }
        }
      }
      .t-flex-component {
        svg {
          font-size: 18px;
        }
      }
      box-shadow: 0px 1px 4px black, 0 -1px 1px black;
      height: 38px;
      min-height: 38px !important; /* TODO cambiar al refactorizar */
      min-width: 38px !important;
      width: 38px;
    }
  }
  &.has-bullets {
    .m-input-text__buttons {
      top: -20px;
    }
    .m-input-text__textarea {
      padding-left: 20px;
      height: 100%;
    }
    .has-bullets__dots {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 1px;
      top: 30px;
      &-circle {
        height: 2.28rem;
        display: flex;
        width: 2.28rem;
        align-items: center;
        justify-content: center;
        & > div {
          background: rgb(114, 114, 114);
          width: 30%;
          height: 30%;
          border-radius: 50%;
        }
      }
    }
  }
}
.m-input-text-r {
  background: $color-gray;
  border-radius: 10px;
  border: none;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  line-height: 2.3rem;
  text-align: justify;
  outline: none;
  padding: 5px;
  resize: none;
  width: 100%;
  transition: .2s;
  &::selection {
    color: var(--color-white);
    background: var(--color-purple);
  }
  &:read-only {
    background: $color-gray;
    color: rgb(75, 75, 75);
    cursor: pointer;
    &::selection {
      background: var(--color-blue);
      color: var(--color-white);
    }
  }
  &:disabled {
    cursor: default;
  }
  .global-edit & {
    background: var(--color-white);
    &::read-only{
      background: var(--color-gray);

    }
    &:disabled{
      background: var(--color-gray);
    }
  } 
}
