.o-file-filter {
  &__success {
    min-height: 120px;
    max-height: 120px;
    height: 100%;
    border-radius: var(--border-radius);
    background: rgba($color-purple, 0.2);
    padding: 10px 10px 20px;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    line-height: 2.8rem;
    position: relative;
    .a-button{
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
    &-title{
      color: var(--color-purple);
      font-weight: 600;
      padding-left: 15px;
      margin-bottom: 5px;
    }
  }

  &__content {
    //TODO refactorizar esto
    &-item {
      animation: opa 1s forwards;
      display: grid;
      width: 85%;
      grid-template-columns: 1fr 3fr;
      gap: 0px;
      margin-bottom: 5px;
    }
    &-qty {
      color: var(--color-purple);
      font-size: 2.5rem;
      text-align: center;
    }
    &-text {
      color: var(--color-purple);
      font-size: 1.7rem;
      padding-left: 10px;
      white-space: nowrap;
    }
  }
}
