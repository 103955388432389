.t-playground{
    background: var(--color-white);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    @include z-index(toast);
    &.is-purple{
        background: var(--color-purple);
    }
}
