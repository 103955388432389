.m-flex-cell {
    word-break: keep-all;
    box-sizing: border-box;
    width: 100%;
    display: flex;

    &.--align-start { align-self: start;}
    &.--align-center { align-self: center;}
    &.--align-end { align-self: flex-end;}
    &.--align-stretch { align-self: stretch;}
    &.--align-normal { align-self: normal;}

    &.--justify-start { justify-content: start;}
    &.--justify-center { justify-content: center;}
    &.--justify-end { justify-content: flex-end;}
    &.--justify-space-around { justify-content: space-around;}
    &.--justify-space-between { justify-content: space-between;}
    &.--justify-space-evenly { justify-content: space-evenly;}
}
