.m-item-counter {
  align-items: flex-end;
  color: var(--color-dark-gray);
  display: flex;
  align-items: center;
  height: 100%;
  cursor: default;
  position: relative;
  &__title {
    font-weight: 600;
    color: var(--color-purple);
    display: block;
    font-size: 1.2rem;
    margin: 0 10px;
    letter-spacing: 0;
    @include md() {
      font-size: 1.6rem;
    }
  }
  &__qtys {
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    @include md() {
      font-size: 2.3rem;
    }
    &-filtered {
      color: var(--color-purple);
      font-weight: 600;
    }
    .a-button-info {
      position: relative;
      @include z-index(third);
      top: -12px;
      left: -6px;
    }
  }
}

.o-new-stats {
  position: absolute;
  top: 130%;
  right: 0;
  background: var(--color-gray);
  padding: 10px 20px;
  border-radius: var(--border-radius);
  box-shadow: 5px 5px 15px 8px rgba(black, 0.2);
  color: var(--color-purple);
  @media screen and (max-width: 600px) {
    background: red;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-total {
      margin-top: 9px;
      font-size: 20px;
      font-weight: 600;
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(20%);
    z-index: 999;
    &:hover {
      background: transparent !important;
    }
  }
}
