.o-gallery {
  height: 100%;
  position: relative;
  overflow: hidden;
  > .t-columns {
    gap: 7px
  }
  &__featured {
    padding: 0 0 15px 15px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__miniature {
    padding-right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    inset: 0px 0px 0px 10px !important;
    .ScrollbarsCustom-Wrapper {
      inset: 0px -10px 0px 0px !important;
    }
    .ScrollbarsCustom-TrackY {
      right: -12px !important;
    }
    &-images {
      height: 100%;
      width: var(--porcent);
      overflow: hidden;
    }
    &-container {
      height: var(--porcent);
      width: var(--porcent);
      margin-bottom: 5px;
      display: grid;
      justify-items: center;
      align-items: center;
      font-size: 2.5rem;
      cursor: pointer;
      border: 1px solid rgba($color: $color-purple, $alpha: 0.3);
      > img {
        max-width: 100%;
        max-height: 100%;
        min-width: 1%;
        min-height: 1%;
      }
      &.is-active {
        border: 2px solid var(--color-purple);
      }
    }
    &-image {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }
  &--landscape {
    /* TODO despues va a ser landscape (Estan invertidos) */
    .o-gallery {
      &__featured {
        padding: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      &__miniature {
        // flex-direction: row;
        // min-width: 100%;
        .ScrollbarsCustom-Content {
          height: 100%;
        }
        &-images {
          height: 100%;
        }
        &-image {
          flex: none;
          margin: 0 10px 0 0;
          height: 100%;
          width: auto;
        }
      }
    }
  }
  &--square {
    // .t-columns__right {
    //   margin: 0 20px;
    // }
    .o-gallery {
      &__featured {
        padding: 0;
        width: 100%;
        height: 100%;
        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
      }
    }
  }
  /* &.is-horizontal {
    .o-gallery {
      &__featured {
        padding: 0;
        width: 100%;
        height: max-content;
      }
      &__miniature {
        display: flex;
        padding-left: 1px;
        align-items: center;
        height: 100%;
        width: 100%;
        &-image {
          height: 100%;
          width: auto;
        }
      }
    }
    .o-image-slider {
      height: max-content;
      width: 100%;
      &__image {
        width: 100%;
        height: auto;
      }
    }
  }
  &.is-square {
    .o-image-slider {
      transform: scale(0.9);
    }
  }
  .no-image {
    margin-left: -80px;
  } */
  &.is-loading {
    opacity: 0;
  }
}
