.o-edit-field-bulk-modal {
  margin-top: 10px;
  border-top: 1px solid var(--color-gray);
  padding-top: 5px;
  width: 420px;
  position: relative;

  &__content {
    .m-input-label {
      margin-top: 15px;
    }
  }
  &__footer {
    margin-top: 15px;
    display: grid;
    justify-items: flex-end;
  }
}
