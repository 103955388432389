.o-new-catalog-modal {
  @include modal-styles;

  &__buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .a-circular-progress {
    margin: 15px auto;
  }

  &__count {
    position: absolute;
    top: 12px;
    right: 15px;
    &-number {
      font-size: 2rem;
      font-weight: 500;
      color: var(--color-purple);
      margin-right: 10px;
    }
    &-text{
      color: var(--color-dark-gray);
      font-size: 1.6rem;
    }
  }
}
