.m-toast {
  color: var(--color-black);
  padding: 10px 20px 15px 45px;
  &__icon {
    font-size: 25px;
    left: 10px;
    position: absolute;
    top: 7px;
    .m-toast--success & {
      color: darken($color-success, 50);
    }
    .m-toast--error & {
      color: darken($color-error, 50);
    }
    .m-toast--info & {
      color: darken($color-blue, 20);
    }
  }
  &__button {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
    .m-toast--success & {
      color: darken($color-success, 50);
    }
    .m-toast--error & {
      color: darken($color-error, 50);
    }
    .m-toast--info & {
      color: darken($color-blue, 50);
    }
  }
  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-family: $font-primary;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .a-button{
      max-width: 20%;
    }
  }
  &__info {
    font-family: $font-primary;
    font-size: 1.65rem;
    font-weight: 400;
    line-height: 2.4rem;
    overflow: hidden;
    padding-right: 20px;
    &-show {
      overflow: visible;
      height: max-content;
    }
    b{
      font-weight: 600;
      font-style: italic;
      font-size: 1.6rem;
    }
  }
}
