.o-add-to-task-form {
  height: 100%;
  @include modal-styles;
  @include modal-buttons;

  &__buttons {
    display: flex;
    margin-top: 50px;
    align-items: flex-end;
  }
}
