@import "./m-image-card.scss";
@import "./m-toolbar.scss";
@import "./t-images-wrapper.scss";

.v-manage-images {
  background: var(--color-white);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  right: 0;
  &__image {
    width: 50%;
    height: 100%;
    padding: 80px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: contain;
      background-image: url("../../../../_utils/images/transparency.jpeg");
      background-size: 50%;
    }
    &--square {
      img {
        height: auto;
        width: auto;
      }
    }
    &--portrait {
      img {
        width: auto;
      }
    }
    &--landscape {
      img{
        height: auto;
      }
    }
  }
  &__grid {
    padding-top: 80px;
    height: 100%;
    width: 50%;
    background: var(--color-gray);
    padding-bottom: 80px;
    position: relative;
    overflow: hidden;
    &-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      color: var(--color-purple);
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      background: var(--color-gray);
      transition: 0.3s;
      .close-button {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 50px;
      }
      .a-button {
        font-size: 2rem;
        font-weight: 600;
        max-height: 30px;
        &:hover {
          ~ span {
            opacity: 1;
          }
        }
      }
      > span {
        position: absolute;
        top: 10px;
        transform: scaleX(5);
        opacity: 0;
      }
      &.grew {
        height: 300px;
      }
    }
  }
}
