.m-filter-group {
  min-height: 100%;
  display: block;

  &__title {
    color: var(--color-purple);
    margin-bottom: 10px;
    height: 20px;
    font-weight: 500;
    display: block;
    cursor: pointer;

    &.is-up {
      svg {
        &:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__filter-switch {
    height: 90%;
    width: 300px;
    .drop-container {
      width: 100%;
      height: 100%;
      position: relative;
      &__button {
        position: absolute;
        max-width: 50px ;
        right: 2px;
        top: 2px;
        .t-flex-component {
          padding: 0;
        }
      }
      &__results {
        border: 2px solid var(--color-purple);
        height: 100%;
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        &-reset {
          position: absolute;
          right: 0;
          bottom: 10px;
        }
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: var(--color-purple);
          width: 70%;
          margin-top: 5px;
          span {
            font-size: 2.2rem;
            font-weight: 600;
            text-align: center;
            width: 30%;
          }
          p{
            text-align: start;
            width: 68%;
            font-size: 1.8rem;
            font-weight: 400;
          }
        }
      }
    }
    .o-upload-drop {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      span {
        max-width: 250px;
        line-height: 30px;
      }
      svg {
        font-size: 4rem !important;
        display: none;
        color: var(--color-purple);
      }
    }
  }

  &__filter-area {
    max-width: 300px;
    width: 100%;
    height: 100%;
    > textarea {
      resize: none;
      height: 100% !important;
      width: 100%;
      border-radius: 10px;
      border: 3px dashed $color-purple;
      padding: 15px;
    }
    > textarea:focus {
      outline: none;
    }
  }

  &__results {
    text-align: left;
  }

  &__content {
    height: 139px;
    @include md() {
      height: 169px;
    }
    &-button {
      max-width: 180px;
      &--span {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 100%;
        padding: 10px 0;
      }
    }
    .t-flex-component {
      width: max-content;
      padding-right: 15px;
      gap: 5px;
      font-size: 1.55rem;
    }
  }
}
