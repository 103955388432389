.o-accordion {
  svg {
    color: var(--color-purple);
  }
  &__header {
    align-items: center;
    border-bottom: 1px solid darken($color-gray, 10);
    border-radius: 0;
    cursor: pointer;
    display: flex;
    height: var(--height-misc);
    justify-content: space-between;
    padding: 0 10px 0 5px;
    width: 100%;
    transition: 0.3s;
    &-title {
      font-weight: 500;
      font-size: 1.85rem;
      color: var(--color-purple);
      .is-colored & {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    &:hover {
      background: var(--color-gray);
      border-radius: var(--border-radius);
      border-color: transparent;
    }
    .o-accordion__arrow {
      color: var(--color-purple);
      transition: 0.3s;
    }
    .is-active & {
      .o-accordion__arrow {
        transform: rotate(180deg);
      }
    }
    .is-colored & {
      background: var(--color-white);
      border-bottom: none;
    }
    .is-colored.is-active & {
      color: var(--color-white);
      background: var(--color-purple);
      svg {
        color: var(--color-white);
      }
    }
  }
  &__content {
    cursor: default;
    max-height: 0px;
    overflow: hidden;
    transition: 0.5s;
    &-body {
      padding: 10px 0px 10px 0;
      height: 100%;
      width: 100%;
    }
  }
  &.is-active {
    .o-accordion__content {
      max-height: max-content;
    }
  }
}
