.t-flex-component {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--padding);
  height: auto;
  width: max-content;
  &.is-vertical {
    grid-auto-flow: row;
    grid-gap: 10px;
    height: max-content;
    width: 100%;
  }
  &.is-sidebar {
    border-bottom: 1px solid #c8b7db;
    border-top: 1px solid #c8b7db;
    padding: var(--padding-vertical);
  }
  &.is-sm {
    grid-gap: 7px;
  }

  &.is-xs {
    grid-gap: 3px;
  }
}
