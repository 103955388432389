@mixin modal-styles {
  margin-top: 10px;
  border-top: 1px solid var(--color-gray);
  padding-top: 5px;
  width: 520px;
  position: relative;

  .m-input-label__label {
    color: var(--color-dark-gray);
    padding: 10px 0 5px;
  }
}

@mixin modal-buttons {
  &__buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
