.o-generate-link-modal {
  margin-top: 10px;
  border-top: 1px solid rgba($color-dark-gray, 0.45);
  padding-top: 10px;
  width: 600px;
  &__names {
    color: $color-purple;
    display: flex;
    justify-content: space-between;
    > div {
      max-width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :last-child {
      font-weight: 600;
    }
    margin: 0 0 15px 0;
  }

  &__info-photo {
    background: var(--color-gray);
    border-radius: var(--border-radius);
    margin: 12px 0;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    
    > div:first-child {
      align-items: center;
      display: flex;
      max-width: 140px;
      width: 140px;
      height: 140px;
    }
    > div:last-child {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
      > * {
        flex: 1;
        flex-basis: 31.5%;
        max-width: 31.5%;
        margin: 5px 3px;
      }
    }
  }
  &__progress-bar {
    margin-top: 20px;
  }
  &__fields {
    margin-top: 20px;
    color: $color-purple;
    font-size: 1.7rem;
    font-weight: 500;
    > div {
      margin-bottom: 15px;
    }
    > .--images-by-item {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      justify-content: flex-start;
      > div:first-child {
        width: 45%;
      }
    }
    .--form-control {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      > div:first-child {
        width: 45%;
      }

      .m-select { margin-left: 8px;}
    }
  }
  &__button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__warning {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    color: var(--color-purple);
    background: #fafafa;
    border-radius: var(--border-radius);
    span {
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-left: 10px;
    }
  }

  .--select-option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    > div:first-child {
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      .a-badge--border-purple { width: 100%;}
      .a-badge__label {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
