.t-panel-left {
  height: 100%;
  padding: 25px var(--padding) var(--padding) 0;
  width: 100%;
  &__content {
    border-radius: var(--border-radius);
    box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
    height: 100%;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  &__button {
    height: 40px;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 40px;
  }
  &--catalogs {
    padding-top: 10px;
    .t-panel-left {
      &__content {
        box-shadow: none;
        overflow: visible;
      }
    }
  }
}
