.o-adaptations-bulk-modal {
    .m-select {
        margin: 10px 0 30px 0;
    }

    padding-top: var(--padding);
    min-width: 550px;
    &__check-confirm {
        padding: var(--padding);
        margin-top: 10px;
        display: flex;
        justify-content:left;
        align-items: center;
    }

    .t-dropdown__content-dropdown{
        width: 550px;
    }

    &__force-option {
        margin: 40px 0 30px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
