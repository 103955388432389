.o-backremoval-modal {
  @include modal-styles;
  
  &__content {
    background-color: $color-light-purple;
    border-radius: $border-radius;
    color: $color-purple;
    display: flex;
    font-size: 1.8rem;
    margin: 15px 0;
    padding: 25px 20px;
    > div { 
      display: flex;
      justify-content: center;
      width: 50%;
    }
  }
}
