.o-user-form-modal {
  @include modal-styles;
  
  &__flex-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    > * {
      flex: 0 0 calc(45%);
    }
  }

  @include modal-buttons;
  &__buttons {
    margin-top: 50px;
  }
}
