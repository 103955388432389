.o-new-property-modal {
  cursor: default;
  margin-top: 10px;
  border-top: 1px solid rgba($color-dark-gray, 0.45);
  padding-top: 10px;
  position: relative;
  &__buttons {
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba($color-dark-gray, 0.45);
  }
}
