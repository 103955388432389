.o-add-layer-modal {
  @include modal-styles;
  @include modal-buttons;

  padding: 10px 0px 0px;
  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--color-purple);
    font-size: 1.7rem;
    margin: 10px 0;
    > span:first-child {
      width: 60%;
    }
  }

  .o-add-layer-modal__form-control {
    margin-bottom: 25px;
  }  
}
