.v-auth {
  background: var(--background-gradient);
  background-size: 400% 400%;
  animation: test 20s ease infinite;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes test {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  &__container {
    background: var(--color-white);
    width: 300px;
    height: max-content;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px 2px black;
    .t-flex-component {
      padding: 0 !important; //TODO arreglar esto cuando ya este al 100
    }
    input{
        padding-left: 10px;
    }
  }
  .link {
    color: $color-purple;
    font-size: 14px;
    text-align: right;
  }
  .submit {
    margin-top: 10px;
  }
  .--errors {
    padding: 0 12px;
    p {
      display: list-item;
      font-size: 1.3rem;
      line-height: 2rem;
      margin: auto;
      width: 95%;
      &.--success { color: $color-green}
    }
  }
}
