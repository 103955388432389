.t-form-control {
  display: flex;
  gap: 6px;
  &.--vertical {
    flex-direction: column;
    &.--reverse {
      flex-direction: column-reverse;
    } 
  }

  &.--vertical &__input-control {
    padding-left: 5px;
    padding-right: 5px;
  }

  &.--horizontal {
    flex-direction: row;
    align-items: center;
    &.--reverse {
      flex-direction: row-reverse;
    } 
    label {
      align-items: center;
      display: flex;
      padding: 0;
    }
  }

  .m-form-label {
    align-items: center;
    display: flex;
  }

  &__input-control {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }
}

