.m-property-card {
  padding: 5px;
  /* background: var(--color-purple); */
  border-radius: var(--border-radius);

  * {
    cursor: default;
  }
  &__division {
    color: var(--color-purple);
    font-weight: lighter;
    &:last-child{
      display: none;
    }
  }
  &__content {
    overflow: hidden;
    background: var(--color-gray);
    min-height: 92px;
    max-height: 100px;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 15px rgba(black, 0.07);
    padding: 10px 20px;
    transition: .3s;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba($color-purple, 0.4);
      padding: 4px 0;
    }
    &-title {
      font-size: 2rem;
      font-weight: 500;
      color: var(--color-purple);
    }
    &-ct {
      text-transform: uppercase;
      font-size: 1.2rem;
      color: var(--color-purple);
      cursor: default;
    }
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 8px 4px 0;
      margin-top: 5px;
    }
    &-description {
      font-size: 1.4rem;
      color: var(--color-dark-gray);
    }
    &-type {
      font-size: 1.4rem;
      color: var(--color-dark-gray);
      text-transform: uppercase;
    }
  }
  &--active {
    background: var(--color-purple);
    max-height: 1000px;
    animation: opa .5s forwards ease-in;
    .m-property-card {
      &__content {
        background: var(--color-white);
        min-height: 325px;
        max-height: 10000px;
        transition: .3s;
        box-shadow: 2px 4px 15px rgba(black, 0.57);
      }
    }
  }
}
