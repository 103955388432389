.o-task-modal {
  cursor: default;
  width: 400px;
  &__flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .t-flex-component {
    padding: 15px 0;
  }
  &__info {
    svg {
      font-size: 3rem;
      color: var(--color-purple);
    }
    &-paragraph {
      padding: 10px 0;
    }
  }
  &__content {
    border: {
      bottom: 1px solid rgba($color-purple, 0.4);
      top: 1px solid rgba($color-purple, 0.4);
    }
  }
  &__title {
    font-size: 1.8rem;
    font-weight: 600;
  }
  &__shared {
    .a-button {
      width: 40px;
      height: 40px;
    }
  }
}
