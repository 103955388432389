.m-grid-selector{
    background: var(--color-gray);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    height: var(--height-misc);
    width: 95px;
    @include md(){
        padding: 5px;
        width: 110px;
    }
    button{
        border-radius: var(--border-radius);
        width: 50%;
        height: 100%;
        min-height: inherit;
    }
}
