.v-settings {
  @include z-index(section);
  position: relative;
  height: 100%;
  height: 100%;
  padding: calc(var(--padding) - 5px) var(--padding) calc(var(--padding) - 10px) 0;
  width: 100%;
  &__content{
    height: 100%;
    width: 100%;
    margin-left: 20px;
  }
}
