/*=============================================
=                  TAGS                       =
html tags
=============================================*/
* {
  border: 0;
  margin: 0;
  padding: 0;
  &::selection {
    background: none;
    color: inherit;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* Css Vars */
:root {
  --border-radius: #{$border-radius};
  --color-black: #{$color-black};
  --color-blue: #{$color-blue};
  --color-gray: #{$color-gray};
  --color-green: #{$color-green};
  --color-light-purple: #{$color-light-purple};
  --color-purple: #{$color-purple};
  --color-red: #{$color-red};
  --color-white: #{$color-white};
  --color-yellow: #{$color-yellow};
  --color-dark-gray: #{$color-dark-gray};
  --color-success: #{$color-success};
  --color-error: #{$color-error};
  --background-gradient: linear-gradient(
    45deg,
    var(--color-purple) 0%,
    var(--color-blue) 100%
  );
  --minimal-dimension: #{$minimal-dimension-sm}; // ! Header height and sidebar width
  --height-misc: #{$height-misc}; // ! Ej: Buttons
  --padding: #{$padding-sm};
  --padding-horizontal: 0 var(--padding);
  --padding-vertical: var(--padding) 0px;
  @include md() {
    --padding: #{$padding-md};
    --minimal-dimension: #{$minimal-dimension-md};
    --height-misc: #{$height-misc-md};
  }
}
html {
  position: relative;
  min-width: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background: var(--color-white);
  font-family: $font-primary;
  font-size: 1.6rem;
  font-size: 16px;
  letter-spacing: 0.3px;
  height: 100%;
}
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  margin: 0;
}
p {
  font-weight: 400;
  margin: 0;
}
input:not([type=checkbox]):not([type=radio]) {
  background: var(--color-gray);
  border-radius: calc(var(--border-radius) - 1.5px);
  border: none;
  height: 100%;
  outline: none;
  width: 100%;

  &::selection {
    color: var(--color-white);
    background: var(--color-purple);
  }
  &:read-only {
    &::selection{
    background: var(--color-blue);
    color: var(--color-white);}
  }
}
.App {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/*=====  End of TAGS                   ======*/
