.a-checkbox {
    display: flex;
    align-items: center;
    &__input {
        display: none;
    }

    &__input + label {
        line-height: 3em;
    }

    &__input:disabled + label {
        color: #ccc !important;
        cursor: not-allowed;
    }

    &__input:checked:disabled + label:after {
        border-color: #ccc;
    }

    &__input + label:before {
        content: "";
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 0.5em;
        border: 2px solid #ccc;
        background: #fff;
        cursor: pointer;
    }

    &__input:checked + label {
        background: #c1eec2;
    }

    &__input:checked + label:after {
        content: "";
        width: 12px;
        height: 6px;
        border: 4px solid $color-green;
        float: left;
        margin-left: -1.5em;
        border-right: 0;
        border-top: 0;
        margin-top: .4em;
        transform: rotate(-55deg);
    }

    &__input:checked + label:before {
        border-color: $color-green;
    }

    &__input:checked:disabled + label {
        background: #ccc;
        color: #fff !important;
    }
    
    &__input:checked:disabled + label:before {
        border-color: #bdbdbd;
    }
}
