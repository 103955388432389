.m-user-row {
  border-radius: var(--border-radius);
  padding: 10px 20px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  color: var(--color-dark-gray);
  input {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: rgba($color-purple, 0.1);
  }
  &.is-active {
    background: rgba($color-purple, 0.1);
  }
  .t-dropdown {
    width: 50px;
    &__content {
      &-dropdown {
        min-width: 210px;
      }
    }
  }
  &.is-catalogs{
    padding: 1px 10px;
  }
}
