.o-metrics {
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    height: var(--height-misc);
    position: relative;
    justify-content: space-between;
    background: var(--color-white);
    width: 100%;
	&__total {
        height: 2rem;
        position: relative;
		&.infinite {
            height: 3.5rem;
		    font-size: 3.5rem;
        }
        svg {
            margin-bottom: -1rem;
        }
	}
}
