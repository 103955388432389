.a-tooltip{
    position: fixed;
    background: rgba($color-purple, 0.9);
    padding: 8px 10px;
    border-radius: 5px;
    color: var(--color-white);
    box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
    border: 1px solid var(--color-white);
    pointer-events: none;
    width: max-content;
    @include z-index("modal");
    &--black{
        background: rgba($color-black, 0.9);
    }
    &--gradient{
        height: auto;
        background: rgba($color-blue, 0.9);
    }
}
