.o-card {
  border-radius: $border-radius $border-radius 0px 0px;
  overflow: hidden;

  &__header, &__bottom {
    align-items: center;
    display: flex;
    height: var(--height-misc);
    position: relative;
    justify-content: space-between;
    background: var(--color-white);
    width: 100%;
  }

  &__header-icon {
    font-size: 3rem;
    color: $color-purple
  }


  box-shadow: $shadow;
}
