.o-details-photo-loader {
  &--homeInit {
    .o-details-photo-loader {
      &__carrousel {
        svg {
          color: var(--color-white);
        }
      }
      &__loading {
        color: var(--color-white);
      }
    }
  }
  &__logo {
    position: absolute;
    top: 25%;
    left: 50%;
    .a-logo {
      width: 150px;
    }
    transform: translate(-50%);
  }
  &__loading {
    margin-top: 10px;
    color: var(--color-purple);
    display: flex;
    align-items: center;
    justify-content: center;
    &-text {
      margin-right: 25px;
      font-size: 3rem;
    }
    .dot-flashing {
      $dimension: 10px;
      $radius: 50%;
      top: 2px;
      width: $dimension;
      height: $dimension;
      border-radius: $radius;
      &::after,
      &::before {
        width: $dimension;
        height: $dimension;
        border-radius: $radius;
      }
      &::after {
        left: 15px;
      }
      &::before {
        left: -15px;
      }
    }
  }
  &__carrousel {
    height: 380px;
    width: 70%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0 50px;
    &-clothes {
      width: 150px;
      svg {
        color: var(--color-purple);
        transition: 0.5s;
      }
      &:hover {
        svg {
          color: rgb(7, 30, 95);
          transform: scale(1.2);
        }
      }
    }
    /* &:hover {
        .t-flex-component {
          animation-play-state: paused;
        }
      } */

    .t-flex-component {
      align-items: space-around;
      animation: carrousel 15s infinite alternate-reverse;
      animation-timing-function: linear;
    }
    @keyframes carrousel {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-30%);
      }
    }
  }
}
