.a-input {
  border: 2px solid transparent !important;
  &:focus {
    border-color: $color-purple !important;
  }
  &:disabled {
    background-color: $color-light-purple;
  }
  &:disabled, &:not([type=radio]):read-only {
    cursor: not-allowed;
  }
  &.--xs {
    font-size: 1.2rem;
    height: 18px;
    padding: 3px 5px;
  }

  &.--sm {
    font-size: 1.4rem;
    height: 30px;
    padding: 3px 7px;
  }

  &.--md {
    font-size: 1.8rem;
    height: 34px;
    padding: 3px 8px;
  }

  &.--lg {
    font-size: 2.3rem;
    height: 38px;
    padding: 5px 8px;
  }

  &.--xl {
    font-size: 2.8rem;
    height: 44px;
    padding: 5px 8px;
  }
}
