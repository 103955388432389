.a-badge {
  display: flex;
  align-items: center;
  > *:nth-child(1) {
    display: flex;
    align-self: stretch;
    align-items: center;
    border-radius: calc(var(--border-radius) / 2);
    font-size: 1.4rem;
    justify-content: center;
    min-height: 22px;
    min-width: 35px;
    overflow: hidden;
    padding: 3px calc(var(--padding) / 2);
  }

  > *:nth-child(2n) {
    span::before {
      content: " ";
      white-space: pre;
    }
    &--large {
      font-size: 1.7rem;
    }
  }
  &--outside {
    min-height: 12px !important;
    min-width: 12px !important;
    padding: 3px !important;
    align-self: center !important;
  }
  &__label {
    color: var(--color-purple);
    text-transform: uppercase;
  }
  &--purple {
    background: var(--color-purple);
    .a-badge {
      &__label {
        color: var(--color-white);
      }
    }
  }
  &--bold {
    font-weight: bold;
  }
  &--no-up {
    .a-badge__label {
      text-transform: initial;
    }
  }
  
  &--border- {
    &purple {
      span {
        color: $color-purple !important;
      }
      border-style: solid;
      border-width: 1px;
      border-color: $color-purple;
      background-color: $color-white;
    }
  }
}
