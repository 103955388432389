/*=============================================
=            Media mixin            =
=============================================*/

@mixin media-up($mq, $mh) {
  @media (min-width: $mq) and (min-height: $mh) {
    @content;
  }
}

@mixin media-down($mq) {
  @media (max-width: $mq) {
    @content;
  }
}

@mixin media-between($mq-min, $mq-max, $mh) {
  @media (min-width: $mq-min) and (max-width: $mq-max) and(min-height: $mh) {
    @content;
  }
}

 
/* SM */
@mixin sm-down() {
  @media (max-width: $screen-desktop-sm) {
    @content;
  }
}// from 0 to 1280px

@mixin sm() {
  @include media-up($screen-desktop-sm, $screen-height-sm) {
    @content;
  }
}// * FROM 1280px to infinity

@mixin only-sm(){
  @include media-between($screen-desktop-sm, $screen-desktop-md, $screen-height-sm){
    @content;
  }
}// * FROM 1280px to 1440px


/* MD */
@mixin only-md() {
  @include media-between($screen-desktop-md, $screen-desktop-xl, $screen-height-md) {
    @content;
  }
}// * FROM 1440px to 1920px

@mixin md() {
  @include media-up($screen-desktop-md, $screen-height-md) {
    @content;
  }
}// * FROM 1440px to infinity

/* XL */
@mixin xl() {
  @include media-up($screen-desktop-xl, $screen-height-xl) {
    @content;
  }
}// * FROM 1920px to infinity
  
  /*=====  End of Media mixin  ======*/
