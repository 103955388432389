.a-title {
  color: var(--color-white);
  cursor: default;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 1.5px;
  max-width: max-content;
  display: block;
  &.is-secondary {
    color: var(--color-purple);
  }
  &.is-black {
    color: var(--color-dark-gray);
  }
  &.is-xlarge {
    font-size: 4rem;
    font-weight: 600;
  }
  &.is-large {
    font-size: 2.8rem;
    font-weight: 600;
  }
  &.is-xmedium {
    font-size: 2rem;
    font-weight: 550;
  }
  &.is-medium {
    font-size: 1.8rem;
  }
  &.is-small {
    font-size: 1.5rem;
  }
  &.is-bold {
    font-weight: 900;
  }
  @include md() {
    font-size: 2rem;
  }
}
