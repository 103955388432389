.a-button-info {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 2px solid var(--color-purple);
  color: var(--color-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  transition: 0.3s;
  font-family: "Roboto", sans-serif;
  &:hover {
    color: var(--color-white);
    background: var(--color-purple);
    transform: scale(1.2);
  }
  &.is-active {
    color: var(--color-white);
    background: var(--color-purple);
    transform: scale(1.2);
  }
}
