.mod-multivende-channel-table {
  width: 100%;
  header, .--content, .--row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    > div {
      align-content: center;
      display: flex;
      flex: 1;
    }
    .--config {
      display: flex;
      flex: 1;
      max-width: 230px;
      justify-content: space-around;
    }
  }

  .--content { 
    flex-direction: column;
    gap: 7px;
  }
  
  header, .--content > .--row {
    gap: 5px 10px;
    > div:first-child {
      max-width: 190px;
    }

    .m-select {
      .--select-option {
        align-items: center;
        display:  flex;
        font-size: 1.1rem;
        gap: 5px;

        .a-badge--border-purple { 
          font-size: 1.1rem;
          padding: 3px 5px;
        }
      }
      .m-select__label-info { font-size: 1rem;}
    }
  }

  header .--config  {
    flex: 1;
    max-width: 230px;
    > div {
      display: flex;
      gap: 1;
    }
  }

  header > div { padding: 5px; }
}
