.o-upload-media-lib-modal {
  @include modal-styles;

  &__content {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    padding: 15px 0;
  }

  &__image-content {
    align-content: center;
    border: 1px dotted $color-purple;
    display: flex;
    flex-wrap: wrap;
    height: 250px;
    justify-content: center;
    max-height: 250px;
    max-width: 50%;
    width: 250px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
    &:hover .--cancel-btn {
      opacity: 0.9;
    }
    .--cancel-btn {
      align-items: center;
      background-color: $color-purple;
      border-radius: 10px;
      color: $color-white;
      cursor: pointer;
      display: flex;
      height: 35px;
      justify-content: center;
      position: absolute;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      transition: 0.4s;
      opacity: 0;
      width: 100px;
    }
  }

  &__loading, &__load-success{
    align-items: stretch;
    color: $color-purple;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    > div {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 50%;
    }
  }

  &__options-content {
    width: 250px;
  }
  @include modal-buttons;
}
