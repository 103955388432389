.o-new-adaptation-modal{
    cursor: default;
    margin-top: 10px;
    border-top: 1px solid rgba($color-dark-gray, 0.45);
    padding-top: 10px;
    width: 640px;
    position: relative;
    &__label{
      display: block;
      margin-bottom: 10px;
      color: var(--color-purple);
      font-size: 1.7rem;
    }
    &__division{
      padding: 10px 0;
      &:first-child{
        border-bottom: 1px solid rgba($color-purple, 0.1);
      }
    }   
    &__buttons{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
}
