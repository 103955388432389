.v-sales-channel {
  width: 100%;
  height: 100%;
  &__content {
    padding: {
      left: 20px;
      right: 0;
    }
  }
}


.v-channel-adaptations {
  height: 100%;
  min-height: 100%;
  &__content {
    height: 100%;
    padding: {
      left: 20px;
      right: 0;
    }
  }
}

.v-channel-attributes {
  height: 100%;
  padding: 5px 30px;

  &__content {
    height: 100%;
    min-height: 100%;
    padding: 1px var(--padding) calc(var(--padding) /2) ;
  }

  &__table {
    height: 100%;
    min-height: 100%;

  }
  &__table-body {
    min-height: calc(100% - 50px);
    height: calc(100% - 50px);
  }
}
