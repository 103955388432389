.o-remove-from-task-modal {
  width: 420px;
  border-top: 1px solid var(--color-gray);
  &__content {
      margin-top: 15px;
      padding-top: 5px;
      position: relative;
      p {
          text-align: justify;
      }
  }

  &__footer {
      margin-top: 20px;
      display: flex;
      justify-content:space-between
  }
}
