.o-global-attribute-modal {
  @include modal-styles;
  
  min-width: 750px;
  width: 770px;
  cursor: default;
  margin-top: 10px;
  border-top: 1px solid rgba($color-dark-gray, 0.45);
  padding-top: 10px;
  position: relative;
  &__content {
    display: grid;
    margin-top: 5px;
    max-height: 500px;
    min-height: 500px;
    .m-select {
      width: 100%;
    }

    .o-accordion__content-body{
      padding: 0px;
    }

    .--key {
      width: 30%;
    }
    .--with-key {
      width: calc(70% - 10px);
      margin-left: 10px;
    }
  }

  &__buttons {
    align-items: flex-end;
    display: flex;
    gap: 20px;
    height: 45px;
    justify-content: flex-end;
  }

  &__basic-settings {
    display: flex;
    justify-content: space-between;
  }

  &__basics {
    min-width: 490px;
    width: 490px;
    display: flex;
    flex-wrap: wrap;
  }

  &__type-n-rules {
    min-width: 210px;
    width: 210px;
  }

  &__file-headers {
    min-width: 100%;
    width: 100%;
  }

  &__headers-table {
    height: 100px;
  }

  .m-input-label{
    margin-bottom: 15px;
  }

  .--form-control, .--form-control-vertical, .--form-label {
    margin-bottom: 18px;
  }

  .--form-control {
    display: flex;
    justify-content: space-between;
    > * {
      display: flex;
      align-content: center;
      gap: 5px;
    }
  }

  .--form-label {
    display: flex;
    gap: 5px;
    font-weight: 500;
  }

  .--form-label-vertical {
    padding: 8px 0px;
    font-weight: 500;
  }

  .--form-control-vertical {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
  }
}
