.upload-bulk-images {
  border-top: 1px solid var(--color-gray);
  margin-top: 10px;
  padding-top: 20px;
  min-width: 350px;
  width: max-content;
  .o-upload-drop,
  .o-uploading-file,
  &__uploading {
    min-height: 160px;
  }
  &__uploading {
    height: 160px;
    background: rgba($color-purple, 0.2);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    > div {
      &:first-child {
        width: 100px;
        height: 100px;
        div {
          width: 100px;
          height: 100px;
        }
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
    
    &__content {
      height: 100%;
      min-width: 250px;
      width: calc(100% - 80px);
      margin-left: 10px;
      align-items: center;
      display: grid;
      grid-gap: 2px;
      grid-template-columns: 1fr 2fr;
      padding: 10px 0;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
      &-title {
        font-size: 1.3rem;
        color: var(--color-purple);
        font-weight: 500;
        grid-column: span 2;
      }
    }
   
  }
  &__found {
    &__content {
      min-height: 160px;
      background: rgba($color-purple, 0.2);
      border-radius: var(--border-radius);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 30px;
      &-item {
        align-items: center;
        display: grid;
        grid-gap: 2px;
        grid-template-columns: 1fr 3fr;
      }
      &-title {
        font-size: 1.3rem;
        color: var(--color-purple);
        font-weight: 500;
        position: relative;
        top: 5px;
      }
      &-txt {
        color: var(--color-dark-gray);
        font-size: 1.7rem;
        padding-left: 10px;
        white-space: nowrap;
      }
      &-qty {
        color: var(--color-purple);
        font-size: 2.5rem;
        font-weight: 500;
        justify-self: flex-end;
        justify-self: center;
        &.--red {
          color: var(--color-red);
        }
      }
    }
    &__buttons {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
