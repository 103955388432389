.m-form-label {
  &.--xs {
    font-size: 1.1rem;
    height: 18px;
    padding: 3px 5px;
  }

  &.--sm {
    font-size: 1.5rem;
    height: 22px;
    padding: 5px 5px;
  }

  &.--md {
    font-size: 1.8rem;
    height: 24px;
    padding: 3px 5px;
  }

  &.--lg {
    font-size: 2.3rem;
    height: 34px;
    padding: 5px 5px;
  }

  &.--xl {
    font-size: 2.8rem;
    height: 40px;
    padding: 5px 5px;
  }
}
