.hide-transition {
    display: block;
    visibility: visible;
    opacity: 1;
    height: 100%;
    -webkit-transition: opacity 800ms, visibility 800ms;
        -moz-transition: opacity 800ms, visibility 800ms;
        -ms-transition: opacity 800ms, visibility 800ms;
        -o-transition: opacity 800ms, visibility 800ms;
        transition: opacity 800ms, visibility 800ms;    
    
    &__is-hidden {
        display: none;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 800ms, visibility 800ms;
        -moz-transition: opacity 800ms, visibility 800ms;
        -ms-transition: opacity 800ms, visibility 800ms;
        -o-transition: opacity 800ms, visibility 800ms;
        transition: opacity 800ms, visibility 800ms;    
    }
}

