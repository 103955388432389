.o-billing-card {
    border-radius: $border-radius;
    overflow: hidden;

    &__content {
        padding: 10px var(--padding);
    }

    &__header, &__bottom {
        padding: calc(var(--padding) * 1.2) var(--padding);
        align-items: center;
        display: flex;
        height: var(--height-misc);
        position: relative;
        justify-content: space-between;
        background: var(--color-white);
        width: 100%;
    }

    &__header-icon {
        font-size: 3rem;
        color: $color-purple
    }

    &__highlight {
        background-color: $color-gray;
    }
}
