.t-modal {
  @include z-index("navigation");
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 80px;
  width: 100%;
  height: 100%;
  &__mask {
    background: rgba($color-black, 0.2);
    height: 100%;
    left: 0;
    position: fixed;
    top: var(--minimal-dimension);
    width: 100%;
    &::before{
      position: fixed;
      content: "";
      top: 0px;
      height: 90px;
      left: 0;
      right: 0;
      background: transparent;
      z-index: 99999999999;
      pointer-events: all;
    }
  }
  &__content {
    background: var(--color-white);
    border-radius: var(--border-radius);
    position: relative;
    padding: 20px;
    min-width: 300px;
    top: -10%;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 45px;
      &-right {
        font-size: 14px;
      }
      color: var(--color-purple);
      h2 {
        font-size: 20px;
      }
    }
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 8px;
    max-width: 40px;
    min-height: 40px !important;
    svg {
      font-size: 1.8rem !important; //TODO fix esto
    }
  }
  &.t-modal--generateLink, &.t-modal--shareModal {
    .t-modal__content-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 35px;
      &-right{
        span{
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
