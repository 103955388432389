.o-scroller {
  transition: .3s;
  .ScrollbarsCustom-Track {
    opacity: 0;
    transition: 1s;
    background: rgba($color-purple, 0.08) !important;
    .ScrollbarsCustom-Thumb {
      background: var(--color-purple) !important;
    }
  }
  &:hover {
    & > .ScrollbarsCustom-Track {
      opacity: 1;
    }
  }
  &.is-horizontal {
    & > .ScrollbarsCustom-Track {
      height: 5px !important;
      background: rgba($color-blue, 0.08) !important;
      .ScrollbarsCustom-Thumb {
        background: var(--background-gradient) !important;
      }
    }
  }
  &__content{
    padding-bottom: 340px;
  }
}
