.t-user-layout {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  padding: {
    left: var(--minimal-dimension);
    top: var(--minimal-dimension);
  }
  &__content {
    padding: {
      top: var(--padding);
      left: var(--padding);
    }
    height: calc(100% - 50px);
    width: 100%;
    position: relative;
   transition: 0.3s; 
    &.has-filter {
      height: calc(100% - var(--tabs-height) - 40px);
      @include md() {
        height: calc(100% - var(--tabs-height) - 40px);
      }
    }
    &.filter-open {
      height: calc(100% - 200px - 50px);
      @include md() {
        height: calc(100% - 250px - 50px);
      }
      &.has-filter {
        height: calc(100% - 200px - 50px - var(--tabs-height));
        @include md() {
          height: calc(100% - 250px - 50px - var(--tabs-height));
        }
      }
    }
    &.menu-fixed{
      padding-left: 190px;
    }
    &--photos, &--copy, &--task, &--publish{
      padding: 0;
      height: 100%;
      &.filter-open, &.has-filter{
        height: 100%;
      }
    }
  }
}
