.m-user {
  .t-flex-component{
    padding: 0;
  }
  &__name {
    font-size: 1.8rem;
    font-weight: 600;
    justify-self: flex-end;
  }
  &__email {
    font-size: 1.4rem;
    font-weight: 400;
    justify-self: flex-end;
  }
}
