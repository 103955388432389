.o-header {
  @include z-index(navigation);
  align-items: center;
  background: var(--background-gradient);
  display: flex;
  height: var(--minimal-dimension);
  justify-content: space-between;
  left: 0;
  padding: var(--padding-horizontal);
  position: fixed;
  right: 0;
  top: 0;
}
