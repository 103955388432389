@import "./o-details-photo-loader.scss";

.o-loader {
    position: absolute;
    z-index: 9999999999;
  &--homeInit {
    background: var(--background-gradient);
    width: 100%;
    height: 100%;
  }
  &--detailsPhoto {
    top: 140px;
    width: 45%;
    left: 80px;
    bottom: 65px;
    background: var(--color-white);
  }
}
