.m-input-selector {
  position: relative;
  cursor: default;
  order: 3;
  &.mid-width {
    grid-column: span 2;
  }
  &.max-width {
    grid-column: span 3;
  }
  &.read-only {
    order: 4;
  }
  &.has-bullets{
    .m-input-text-r{
      padding-left: 20px;
    }
  }
}
