.m-nubesk-loader {
  display: flex;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100vw;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;

  &.--show{
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
  }
  &.--hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
  }
}
