.o-catalog-template-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  overflow: hidden;
  border-radius: var(--border-radius);
  padding-left: 10px;
  color: var(--color-dark-gray);
  flex-basis: 100%;
  cursor: default;
  position: relative;
  svg {
    color: var(--color-purple);
  }

  &:hover {
    background: rgba($color-purple, 0.1);
  }
  .download-date {
    font-size: 1.5rem;
    color: var(--color-purple);
  }
  &--download {
    .t-flex-component {
      &:last-child {
        .a-button {
          width: 30px;
        }
      }
    }
  }
  .t-dropdown {
    width: 45px;
    &__content {
      &-dropdown {
        min-width: 225px;
        .a-button {
          width: 100% !important;
        }
      }
    }
  }
}
