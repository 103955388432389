.u-bg-black { background-color: $color-black !important }
.u-bg-blue { background-color: $color-blue !important }
.u-bg-dark-gray { background-color: $color-dark-gray !important }
.u-bg-error { background-color: $color-error !important }
.u-bg-gray { background-color: $color-gray !important }
.u-bg-green { background-color: $color-green !important }
.u-bg-light-green {background-color: $color-light-green !important }
.u-bg-light-purple { background-color: $color-light-purple !important }
.u-bg-purple { background-color: $color-purple !important }
.u-bg-red { background-color: $color-red !important }
.u-bg-success { background-color: $color-success !important }
.u-bg-white { background-color: $color-white !important }
.u-bg-yellow { background-color: $color-yellow !important }
