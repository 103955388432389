.m-custom-progress-bar {
  margin-top: 10px;
  cursor: default;
  &__header {
    color: var(--color-purple);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 1.8rem;
    font-family: "Poppins";
    font-weight: 400;
    padding: 0 5px;
    &-qty {
      font-weight: 500;
      font-size: 1.9rem;
      display: flex;
      align-items: center;
      margin: -3px;
      > * {
        margin: 3px;
      }
    }
    &-total {
      font-size: 1.7rem;
      font-weight: 400;
    }
    &-percent {
      font-weight: 600;
    }
  }
}
