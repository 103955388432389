.m-sku-card-content {
  height: 100%;
  position: relative;
  .t-flex-component {
    padding: 0;
  }
  &__title {
    align-items: center;
    background: var(--color-white);
    border-radius: var(--border-radius);
    display: flex;
    height: 40px;
    width: 100%;
    padding: 10px;
  }
  &__product-name {
    font-size: 1.5rem;
    font-weight: 600;
    min-width: 150px;
    min-height: 22px;
    display: block;
    white-space: nowrap;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__description {
    font-size: 1.3rem;
    height: calc(1.3rem * 8);
    line-height: 2rem;
    overflow: hidden;
    white-space: pre-line;
    width: 100%;
    padding-right: 5px;
  }
  &__buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
