.o-error-upload {
  background: var(--color-gray);
  border-radius: var(--border-radius);
  height: 100%;
  margin-top: 2px;
  padding: 12px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
