.mod-syncfonia-gtin-fetch-modal {
  @include modal-styles;
  $bg-color: $color-light-purple + '70';
  color: $color-purple;
  min-height: 140px;
  padding: 10px 0;

  &__paste-label {
    font-size: 1.6rem;
    margin: auto;
    position: absolute;
    opacity: .5;
    text-align: center;
    top: 50px;
    width: 100%;
  }
  &__paste-loading {
    align-items: center;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    position: absolute;
    margin-top: -148px;
    min-height: 150px;
    min-width: 100%;
  }
  .m-filter-group__filter-area {
    min-width: 100%;
    min-height: 100px;
    position: absolute;
    textarea{
      background-color: transparent;
    }
  }

  &__showfound {
    background-color: #{$bg-color};
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    > div {
      display: flex;
      font-size: 1.8rem;
      font-weight: 500;
      div {
        display: flex;
        align-items: center;
        
        &:first-child {
          font-size: 2.2rem;
          justify-content: right;
          padding-right: 10px;
          width: 30%;
        }
      }
    }
  }

  &__processing {
    > p {
      background-color: #{$bg-color};
      border-radius: $border-radius;
      padding: 10px;
      margin-bottom: 15px;
    }
    .--loading {
      background-color: #{$bg-color};
      border-radius: $border-radius;
      display: flex;
      flex-direction: row;
      font-weight: 500;
      justify-content: space-between;
      min-height: 120px;
      min-width: 500px;
      padding: 0px 10px;
      > div:first-child, > div:last-child { 
        align-items: center;
        display: flex;
        gap: 5px;
        justify-content: center;
      }

      > div:first-child {
        width: 40%;
      }

      > div:last-child {
        display: flex;
        flex-direction: column
      }

      .--percent { 
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &__results {
    background-color: #{$bg-color};
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    gap: 20px;
    padding: 25px 10px;
    > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;
      >  div {
        align-items: center;
        display: flex;
        width: 220px;
        gap: 8px;
        &:last-child {
          justify-content: start;
          width: 30%;
        }
      }
    }

    
  }

  @include modal-buttons;
  &__buttons div:last-child {
    display: flex;
    gap: 20px;
  }
}
