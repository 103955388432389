.m-master-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  position: relative;
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: var(--border-radius);
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    .is-selected & {
      background: var(--color-purple);
      color: var(--color-white);
      &:hover {
        background: var(--color-purple);
        .m-master-row__generate-button {
          &:hover {
            border-color: var(--color-white);
          }
        }
      }
    }
    &:hover {
      background: rgba(180, 149, 214, 0.377);
    }
    .--row-badge {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      .a-badge--border-purple {
        height: 25px;
        overflow: hidden;
        max-width: 95px;
        width: 95px;
        min-width: 95px;
        span { 
          color: $color-purple !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  &.empty {
    .m-master-row {
      &__data {
        cursor: default;
        &:hover {
          background: var(--color-white);
        }
      }
    }
    &.is-selected {
      background: var(--color-white);
      .m-master-row {
        &__data {
          background: var(--color-white);
          color: var(--color-black);
        }
      }
    }
  }
  &__dots-button {
    max-width: 45px;
  }
  &__generate-button {
    border-bottom: 1px solid transparent;
    &:hover {
      border-color: var(--color-black);
    }
    &:disabled {
      color: var(--color-dark-gray);
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__save-button {
    min-height: 28px !important;
    height: 28px !important;
  }
  &__qty {
    font-weight: 400;
    color: var(--color-purple);
    .is-selected & {
      color: var(--color-white);
    }
  }
  &__title {
    font-weight: 400;
    .is-selected & {
      font-weight: 500;
    }
  }

  .t-dropdown {
    width: 50px;
    &__content {
      &-dropdown {
        min-width: 210px;
      }
    }
  }
  &__input {
    min-width: 12ex;
    box-sizing: content-box;
    padding: 0px 5px;
    background: var(--color-gray);
    box-shadow: 1px 1px 10px 0 var(--color-black);
    text-align: right;   
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:read-only {
      cursor: pointer;
      background: none;
      box-shadow: none;
      color: var(--color-black);
      .is-selected & {
        color: var(--color-white);
      }
    }
  }
}
