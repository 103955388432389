.v-products {
  height: 100%;
  min-height: 100%;
  position: relative;
  /* transition: .1s; */
  &::after {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 50px;
    left: 0;
    position: absolute;
    bottom: 0;
    right: 30px;
  }
  &.has-left-panel {
    &::after {
      left: 30%;
    }
  }
}
