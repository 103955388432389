.a-button {
  align-items: center;
  border-radius: var(--border-radius);
  color: var(--color-white);
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  min-height: var(--height-misc);
  min-width: 45px;
  outline: none;
  overflow: hidden;
  padding: 0 calc(var(--padding) / 2);
  white-space: nowrap;
  text-overflow: ellipsis;
  &:disabled {
    filter: grayscale(0.7);
    -webkit-filter: grayscale(0.7);
    opacity: 0.5;
  }
  svg {
    font-size: 2.4rem;
    @include md() {
      font-size: 2.6rem;
    }
  }
  .a-circle-status {
    align-self: flex-start;
    height: 8px;
    width: 8px;
  }
  &--primary {
    background: #fcfbfd;
    border: 2px solid var(--color-purple);
    color: var(--color-purple);
    svg {
      font-size: 1.8rem;
      transition: 0.3s;
    }
    &:hover {
      background: #eee9f4;
    }
  }
  &--delete {
    background: #fcfbfd;
    border: 2px solid var(--color-red);
    color: var(--color-red);
    svg {
      font-size: 1.8rem;
      transition: 0.3s;
    }
    &:hover {
      background: #fff2f2;
    }
  }
  &--purple {
    background: var(--color-purple);
    color: var(--color-white);
    &:hover {
      background: darken($color-purple, 5);
    }
    &:disabled {
      background: lighten($color-purple, 25);
    }
  }
  &--success {
    background: rgba($color-green, 0.06);
    border: 2px solid var(--color-green);
    color: var(--color-green) !important;
    span {color: var(--color-green) !important;}
  }
  &--toast {
    color: darken($color-error, 50);
    border: 1px solid transparent;
    min-height: 30px;
    &:hover {
      background: var(--color-error);
      border-color: darken($color-error, 50);
    }
  }
  &--color {
    color: var(--color-purple);
    min-height: var(--height-misc);
    width: 100%; /* 
    max-width: max-content; */
    &:hover {
      background: lighten($color-gray, 0);
    }
  }
  &--gray {
    color: rgba($color-dark-gray, 0.8);
    border: 2px solid rgba($color-dark-gray, 0.8);
    &:hover {
      background: darken($color-gray, 2);
    }
    &.has-qty {
      font-size: 1.5rem;
      font-weight: 400;
      justify-content: space-between;
      min-height: 56px;
      padding-right: 20px;
      text-transform: uppercase;
      .a-button__qty {
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
  }
  &--tabs {
    @extend .a-button--gray;
    background: rgba($color-gray, 0.7);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border: none;
    color: var(--color-purple);
    transition: 0.3s;
    .a-circle-status {
      align-self: center;
      height: 10px;
      width: 10px;
    }
    svg {
      font-size: 2.2rem;
    }
    .t-flex-component {
      gap: 8px !important;
    }
    &.is-active {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.253);
    }
    &:disabled {
      background: darken($color-gray, 5);
      color: var(--color-dark-gray);
      .a-circle-status {
        background: var(--color-dark-gray);
      }
    }
  }
  &--blue {
    background: var(--color-blue);
    min-width: 55px;
    &:hover {
      background: darken($color-blue, 5);
    }
    &.is-active {
      background: darken($color-blue, 5) !important;
    }
  }
  &--context {
    background: var(--color-white);
    color: var(--color-purple);
    width: 100%;
    .t-flex-component {
      width: 100%;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        svg {
          margin-right: 12px;
        }
      }
    }
    .shortcut {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 500;
      margin-left: 20px;
      color: var(--color-dark-gray);
    }
    &:hover {
      background: var(--color-gray);
    }
  }
  &--context-delete {
    @extend .a-button--context;
    color: rgba($color-red, 0.6);
    &:hover {
      color: var(--color-white);
      background: rgba($color-red, 0.6);
    }
  }
  &--border-blue {
    background: rgba($color-blue, 0.06);
    border: 2px solid var(--color-blue);
    color: var(--color-blue);
  }
  &--dropdown {
    color: var(--color-purple);
    justify-content: flex-start;
    height: 40px;
    min-height: 40px;
    padding: 10px;
    &:hover {
      background: rgba($color-purple, 0.08);
    }
    &.is-selected {
      background: rgba($color-purple, 0.08);
    }
  }
  &.is-active {
    background: var(--color-purple);
    color: var(--color-white);
    &:hover {
      background: var(--color-purple);
    }
  }
  &.is-square {
    max-width: 50px;
    max-height: 50px;
    overflow-y: visible;
    padding: 0;
  }
  &--tab {
    background: var(--color-purple);
    position: relative;
    font-size: 1.4rem;
    padding: 7px;
    padding-right: 25px;
    min-height: max-content;
    svg {
      font-size: 15px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  &--filter {
    color: var(--color-purple);
    min-height: 20px;
    padding: 0;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    &:hover {
      border-color: var(--color-purple);
    }
  }

  &.is-small {
    height: calc(var(--height-misc) / 2);
    font-size: 1.55rem;
    height: 32px;
    min-height: 32px;
  }
  
  &.is-xsmall {
    height: calc(var(--height-misc) / 2);
    span {
      font-size: 1.3rem;  
    }
    font-size: 1.3rem;
    height: 25px;
    min-height: 25px;
  }

  &--select {
    background: var(--color-white);
    padding: 5px;
    min-height: max-content;
    height: 33px;
    font-size: 1.6rem;
    justify-content: space-between;
    border-radius: 10px;
    color: black;
    width: 100%;
    .t-flex-component {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: var(--color-black);
      svg {
        color: var(--color-purple);
        font-size: 18px;
      }
    }
    &:hover {
      background: var(--color-white);
    }
  }
  &--underline {
    border: none;
    color: var(--color-purple);
    min-height: max-content;
    min-width: max-content;
    padding: 0;
    border-radius: 0;
    .t-flex-component {
      gap: 2px;
    }
    span {
      font-size: 1.8rem;
      border-bottom: 1px solid var(--color-purple);
    }
  }

  &--link {
    border: none;
    color: var(--color-purple);
    min-height: max-content;
    min-width: max-content;
    padding: 0;
    border-radius: 0;
    .t-flex-component {
      gap: 2px;
    }
    span {
      font-size: 1.8rem;
    }
    &:hover span {
      text-decoration: underline;
    }
  }

  > .t-flex-component {
    overflow: hidden;
  }

  &.is-fullwidth {
    > .t-flex-component {
      justify-content: space-between;
      flex: 1 auto;
      width: 100%;
    }
  }

  &__span {
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: inherit;
  }
}
