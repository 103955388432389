.o-sales-card {
  border-radius: var(--border-radius);
  box-shadow: 5px 5px 12px 8px rgba(black, 0.07);
  overflow: hidden;
  padding: 0 10px;
  &__header {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray);
    padding: 0 13px;
  }
  &__title {
    color: var(--color-purple);
  }
  &__footer {
    height: 0%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &-user {
      font-size: 1.8rem;
      color: var(--color-purple);
    }
    &-date {
      font-size: 1.6rem;
      color: var(--color-purple);
    }
  }
}
