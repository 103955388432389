.m-priority-selector {
  min-width: 120px;
  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    $bgColor: $color-dark-gray + '40';
    background-color: #{$bgColor};
    @include z-index("navigation");
  }
  &__container {
    @include z-index("navigation");
    background-color: $color-white;
    border-radius: $border-radius;
    padding: calc(var(--padding) / 2 ) var(--padding);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 10px;
    margin-top: -50px;
    margin-left: -100px;
    box-shadow: $shadow-gray;
  }
  button {
    min-width: 120px;
    max-width: 120px;
    
    &.--medium { 
      border-color: $color-orange;
      color: $color-orange;
    }
    &.--normal {
      border-color: $color-blue;
      color: $color-blue;
    }
    &.--high {
      border-color: $color-red;
      color: $color-red;
    }
  }
}
