
/*=============================================
=                 UTILITIES                   =
For !important && misc
=============================================*/
.u-big-icon{ /* TODO modificar despues y quitar de aqui */
    height: 20px;
    min-height: 40px !important;
    svg{
        font-size: 24px;
    }
}
.dot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    top: -5px;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -10px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 10px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
  .CircularProgressbar-path {
    stroke: var(--color-purple) !important;
  }
  .CircularProgressbar-trail {
    stroke: rgba($color-white, 0.9) !important;
  }
  .CircularProgressbar-text {
    font-weight: 600;
    fill: var(--color-purple) !important;
  }

@import "./u-bg-color.scss";
@import "./u-display.scss";
@import "./u-text.scss";
@import "./u-skeleton.scss";
@import "./u-spiner.scss";
@import "./u-react-toastify.scss";
@import "./u-text-color.scss";
@import "./u-without-gap.scss";
@import "./clipping-magic.scss";

/*=====        End of UTILITIES          ======*/

