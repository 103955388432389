.o-catalogs-list {
  height: 100%;
  padding: 15px;
  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .a-button--tabs {
      min-width: 60px;
    }
  }
  &__table {
    background: var(--color-white);
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 41px rgba($color-black, 0.07);
    margin-top: 20px;
    height: calc(100% - 40px);
    margin-bottom: 20px;
  }
  &__filter {
    background: var(--color-white);
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 41px rgba($color-black, 0.07);
    padding: 10px 20px 0;
    &-header {
      padding: 10px 0;
      border-bottom: 1px solid var(--color-gray);
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 2rem;
        font-weight: 500;
        color: var(--color-purple);
      }
      svg{
        color: var(--color-purple);
      }
    }
    &-content{
      padding: 20px 0;
    }
  }
}
