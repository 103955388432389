.m-icon-drawer {
    position: relative;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
        position: absolute;
        animation: circle 3s linear infinite;
    }
}
