$color-blue-clipping: rgba(21, 116, 255, 0.98);
#clipping-magic-container {
  background: var(--color-white) !important;
  border-radius: var(--border-radius) !important;
  bottom: 90px !important;
  box-shadow: 5px 5px 15px 8px rgba(black, 0.07) !important;
  height: auto !important;
  right: 20px !important;
  transition: 0.15s;
  width: auto !important;
  z-index: 120 !important;
  bottom: 20px !important;
  left: 90px !important;
  top: 80px !important;
}
.u-smart-editor {
  bottom: 20px;
  left: 90px;
  pointer-events: none;
  position: fixed;
  right: 20px;
  top: 80px;
  transition: 0.15s;
  z-index: 99999999999999999999;
  &__toolbar {
    height: 43px;
    left: 10px;
    padding-left: 188px;
    position: absolute;
    top: 10px;
    width: 565px;
    transition: 0.3s;
    &-button {
      align-items: center;
      background: $color-blue-clipping;
      color: darken($color-white, 10);
      display: flex;
      height: 100%;
      justify-content: center;
      pointer-events: all;
      width: 50px;
      &:hover {
        color: var(--color-white);
      }
    }
  }
  &__loader {
    align-items: center;
    background: var(--color-purple);
    border-radius: var(--border-radius);
    bottom: 0;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    pointer-events: all;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1009999999;
    span {
      font-size: 3rem;
      cursor: default;
    }
    svg {
      margin-bottom: 13px;
      font-size: 9rem;
    }
  }
  &__sidebar {
    position: absolute;
    right: 10px;
    width: 73px;
    top: 10px;
    bottom: 10px;
    &-close {
      background: $color-blue-clipping;
      color: var(--color-white);
      height: 45px;
      width: 100%;
      pointer-events: all;
      font-size: 1.3rem;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
    &-mode {
      background: var(--color-white);
      height: 35px;
      margin-top: 15px;
      pointer-events: all;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      color: var(--color-dark-gray);
      cursor: default;
    }
    &-soon {
      background: var(--color-gray);
      font-size: 10px;
      height: 42px;
      margin-top: 40px;
      pointer-events: all;
      width: 100%;
      color: var(--color-dark-gray);
    }
  }
  &__footer {
    position: absolute;
    left: 10px;
    bottom: 10px;
    height: 43px;
    width: 590px;
    padding-left: 100px;
    transition: 0.3s;
    &-info {
      height: 100%;
      background: $color-blue-clipping;
      width: 100%;
      pointer-events: all;
      display: flex;
      align-items: center;
      color: var(--color-white);
      padding-left: 20px;
      cursor: default;
      font-size: 1.4rem;
    }
  }
  &--full-screen {
    top: 80px;
    bottom: 20px;
    left: 90px;
    .u-smart-editor {
      &__toolbar {
        padding-left: 212px;
      }
      &__footer {
        width: 550px;
      }
    }
  }
  @media (min-width: 2000px) {
    .u-smart-editor {
      &__toolbar {
        padding-left: 212px;
      }
      &__footer {
        width: 550px;
      }
    }
  }
}
