.m-multiple-progress {
  min-height: 48px;
    &__labels {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      &-label {
        &-sqr {
          width: 10px;
          height: 10px;
          background: $color-purple;
          &.--blue {
            background: $color-blue;
          }
          &.--green {
            background: #35bc4b;
          }
          &.--yellow {
            background: #deef1f;
          }
          &.--red {
            background: $color-red;
          }
          &.--gray {
            background: rgb(236, 235, 235);
          }
        }
        &-number,
        &-title {
          font-size: 1.2rem;
          color: var(--color-purple);
          &.--to-approve {
            font-weight: 600;
            font-size: 1.3rem;
          }
        }
      }
    }
    &__bar {
      margin-top: 5px;
      width: 100%;
      height: 30px;
      background: var(--color-gray);
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      &__item {
        position: absolute;
        width: 0%;  
        height: 100%;
        border-radius: 15px;
        &--purple {
          background: $color-purple;
          transition: 0.1s;
        }
        &--blue {
          background: $color-blue;
          transition: 0.2s;
        }
        &--green {
          background: #35bc4b;
          transition: 0.3s;
        }
        &--yellow {
          background: #deef1f;
          transition: 0.4s;
        }
        &--red {
          background: $color-red;
          transition: 0.5s;
        }
      }
    }
  }
