.o-resource-progress {
    color: $color-purple;
    
    &__animation {
        > * {
            margin: auto;
        }
    }
    &__info, &__counter {
        display: flex;
        flex-direction: row;

        > * {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 1;
        }
    }
    &__info {
        padding: var(--padding);
        border-radius: $border-radius;
        $bg-color: $color-light-purple + '70';
        background-color: #{$bg-color};
    }
    &__state {
        font-weight: 600;
        padding: 0 0 15px;
    }
    &__counter {
        font-size: 2rem;
        padding: 0 0 15px;

        > * {
            justify-content: space-between;
        }
    }
    &__buttons {
        padding-top: var(--padding);
        display: flex;
        justify-content: space-between;
    }
}
