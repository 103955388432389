.mod-multivende-publish-rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 10px;
  > .--row {
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;

    &.--selected {
      background-color: $color-purple;
      color: $color-white !important;
      &:hover { background-color: $color-purple; }
      .--datetime {
        color: $color-white !important;
      }
      
      > div svg {color: $color-white !important}
    }
    &:hover {
      background-color: $color-light-purple;
    }
    .--status {
      display: flex;
      justify-content: center;
    }
    > div:nth-child(1){
      align-items: center;
      display: flex;
      gap: 5px;
      min-width: 200px;
      max-width: 200px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    > div:nth-child(2){
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      gap: 2px;
      min-width: 100px;
      max-width: 100px;
    }
    
    > div:nth-child(3) {
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      font-weight: 400;
      gap: 15px;
      justify-content: flex-end;
      min-width: calc(100% - 350px);
      max-width: calc(100% - 350px);
      
      > div {
        max-width: 250px;
        min-width: 100px;
      }
      > div:first-child {
        color: $color-black;
      }
      .--publish {
        display: flex;
        margin: auto;
        justify-content: flex-end;
        min-width: 80%;
      }
    }

    > div:nth-child(4) {
      display: flex;
      justify-content: space-between;
      min-width: 20px;
      max-width: 20px;
      
    }
  }

  .--disconnected {
    align-items: center;
    border: 1px solid $color-purple;
    border-radius: $border-radius;
    display: flex;
    gap: 5px;
    padding: $padding-sm $padding-md;
    width: 100%;
    div { flex: auto }
    div:first-child { flex: none }
    div:last-child { flex: none }
  }
}
