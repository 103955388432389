.o-progress-card {
    box-shadow: $shadow;
    border-radius: $border-radius;
    &__label {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--color-purple);
        padding: calc(var(--padding) / 2) var(--padding) 0;
        font-size: 1.8rem;
        svg {
            font-size: 2.2rem;
        }
    }
    &__content {
        padding: 5px var(--padding) var(--padding);
    }
}
