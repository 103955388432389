.o-channel-adaptation-card {
  border-radius: $border-radius;
  display: inline;
  overflow: hidden;

  &__header {
    background-color: $color-light-purple;
    color: $color-purple;
    height: var(--height-misc);
    min-height: 38px;
    min-width: 100%;
    padding: 0px 15px 0px 10px;
    width: 100%;
    > *:first-child {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    > *:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
    }
    .--key { 
      background-color: $color-white;
      border: 1px solid $color-purple;
      border-radius: $border-radius;
      font-weight: 600;
      padding: 1px 10px;
      width: auto;
    }
    .--name { height: fit-content; }
    button span { 
      color: $color-purple;
      font-size: 1.4rem;
    }

  }

  &__content {
    display: flex;
    min-height: 250px;

    .--dimensions {
      align-items: center;
      padding-left: 15px;
      border-right: 1px solid;
      border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(50, 50, 50, .4), rgba(255, 255, 255, 0)) 1 100%;
      display: flex;
      min-width: 63%;
      width: 63%;
      gap: 3px;

      .--preview {
        align-items: center;
        display: flex;
        height: calc(100% - 20px);
        justify-content: center;
        min-width: 25%;
        padding: 15px;
        width: 25%;

        .--overlayer {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 15px;
          justify-content: flex-end;
          padding-bottom: 10px;
          > * {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: calc(100% / 5);
          }
          &:hover {
            .--addBtn, .--editBtn, .--deleteBtn {
              opacity: 1;
            }
          }
          .--addBtn, .--editBtn, .--deleteBtn {
            color: $color-white;
            cursor: pointer;
            opacity: 0;
            border-radius: 10px;
            font-size: 1.4rem;
            padding: 4px 8px;
            transition: 0.5s;
          }
          .--addBtn, .--editBtn {
            background-color: $color-purple;
          }
          .--deleteBtn {
            background-color: $color-red;
          }
        }
      }

      .--form {
        min-width: 75%;
        display: grid;
          grid-template-areas: 
          "a b c"
          "d e f"
          "g h i";
        gap: 5px;
        grid-template-columns: 110px 110px 195px;
        grid-template-rows: 65px 65px 65px;
        width: 72%;

        .m-button-color__backdrop {
          @include z-index(navigation)
        }
      }

      .--width {grid-area: a;}
      .--height {grid-area: b;}
      .--type {grid-area: c;}
      .--type-bg {grid-area: f;}
      .--resolution {grid-area: d;}
      .--margins {grid-area: h;}
      .--size {grid-area: e}
      .--extension {
        grid-area: g;
      }
      .--color {
        grid-area: i;
        display: inline-block;
      }
      .--form-control {
        .t-border-inputs {
          width: 55px;
        }
      }
    }

    .--renaming {
      align-content: center;
      padding: 0px 7px 0px 30px;
      min-width: 36%;
      width: 36%;
      display: grid;
      gap: 5px;
      grid-template-areas: 
        "a a b"
        "c d e"
        "f f g";
      grid-template-columns: 115px 115px 70px;
      grid-template-rows: 65px 65px 65px;

      .--type { grid-area: a;}
      .--empty-first { 
        grid-area: b; 
        text-align: center;
        > div { margin: auto; width: 20px;}
      }
      .--nomenclature {
        grid-area: c;

        &.--only-one {
          grid-column-start: c;
          grid-column-end: e;
          font-size: 1.3rem;
          .--email {
            margin-top: 10px;
            a {
              color: $color-purple;
              text-decoration: underline;
            }
          }
        }
      }
      .--separator { grid-area: d; }
      .--start {grid-area: e; }
      .--folding { grid-area: f; }
    }
    .--space-between {
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
    }
    .--form, .--renaming {
      label {
        width: 100%;
        display: inline-block;
        font-size: 1.3rem;
        margin-bottom: 3px;
      }
    }

    .--form-control {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      gap: 5px;
      .t-border-inputs {
        width: 78px;
        textarea, span {font-size: 1.3rem;}
      }
      label {
        width: 100%;
        font-size: 1.3rem;
      }
    }
  }  
}
