.o-details-copy {
  border-radius: var(--border-radius);
  box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
  /* padding: 0px 20px 0; */
  margin: 5px 10px;
  background: white;
  height: calc(100% - 27px);
  transition: .3s;
  &__header {
    align-items: center;
    border-bottom: 1px solid var(--color-gray);
    color: var(--color-purple);
    cursor: default;
    display: flex;
    font-weight: 500;
    font-style: italic;
    justify-content: space-between;
    margin: 0 20px;
    padding: 20px 0 10px;
    .a-title{
      font-size: 1.8rem;
    }
    &-icon {
      color: var(--color-purple);
      font-size: 25px;
    }
    .a-badge, .a-badge--border-purple, .a-badge__label {
      width: 100%;
      .--layer {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        width: 100%;
        div:last-child {
          align-items: center;
          display: flex;
          gap: 5px;
        }
      }
    }
  }
  &.global-edit{
    background: var(--color-gray);
    .o-details-copy{
      &__header{
        border-color: var(--color-white);
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    padding: 10px 20px 0px;
    > div { width: auto;}
    > div:last-child {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: flex-end;
    }
  }
  &__content {
    padding: 20px 0px;
    width: calc(100% - 5px);
    max-width: 100%;
    height: calc(100% - 95px);
    &-inputs {
      padding: 0px 10px 60px 15px;
      display: grid;
      grid-template-columns: 50% 50%;
      
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: auto;

      grid-auto-flow: row dense;
      column-gap: 5px;
      row-gap: 15px;

      label {
        padding: 0 5px;
      }
      .m-form-textarea { 
        height: 115px;
        width: 100%;
      }
      .m-select {
        width: 100%;
      }
      
      .m-form-input {
        grid-column: span 1;
        grid-row: span 1;
      }

      .m-form-textarea {
        grid-column: span 1;
        grid-row: span 2;
      }
    }
  }
}

.o-details-task {
  height: calc(100% - 60px);
  padding: 10px;
  &__wrapper {
    height: calc(100% - 80px);
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding: 10px 20px;
    .card-task {
      background: var(--color-gray);
      border-radius: 10px;
      margin-top: 15px;
      width: max-content;
      display: block;
      padding: 15px 20px;
      min-width: 50%;
      max-width: 100%;
      &__header,
      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__header {
        .icon {
          background: var(--color-purple);
          color: var(--color-white);
          font-size: 2.2rem;
          font-weight: 400;
          letter-spacing: -2px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
        &-name {
          font-weight: 500;
        }
      }
      &__description {
        padding: 15px 25px;
      }
      &__footer {
        &-status {
          color: var(--color-purple);
          font-size: 14px;
          font-style: italic;
        }
        .a-button {
          height: 30px;
          min-height: 30px;
        }
      }
      &.is-solved {
        background: rgba($color-blue, 0.11);
        padding: 15px 20px;
        min-width: 360px;
      }
    }
  }
  &__footer {
    height: 50px;
    width: 100%;
    padding: 0 10px;
    .t-border-inputs {
      height: 50px;
    }
    &-input {
      width: 100%;
      background: white;
      height: 100%;
      min-height: 50px;
      border-radius: 10px;
      position: relative;
      input {
        background: white;
        padding: 0 10px;
        &::selection {
          background: var(--color-purple);
          color: white;
        }
      }
      .o-details-task__footer-input-butons {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .a-button {
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;
      }
    }
  }
}
