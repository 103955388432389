.o-details-publish {
  border-radius: var(--border-radius);
  box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
  padding: 10px 20px 0 0;
  margin: 5px 10px;
  background: white;
  height: calc(100% - 27px);
  transition: .3s;
  width: 100%;

  &__content {
    align-items: center;
    border-bottom: 1px solid var(--color-gray);
    color: var(--color-purple);
    cursor: default;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-style: italic;
    margin: 0 20px;
    width: 100%;
    padding-right: 10px;
    
    > div { width:100%}
    .o-accordion__header {
      img {
        height: 20px;
        width: 20px;
      }
      span {
        font-size: 1.8rem;
        
      }
      &-icon {
        color: var(--color-purple);
        font-size: 25px;
      }
    }
  }
}
