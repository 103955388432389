.m-select {
  .t-dropdown--button.is-xs .a-button--dropdown {
    font-size: 1.4rem !important;
  }
  > div.t-border-inputs:not(.is-focused) {
    $color1: $color-purple + '30';
    background: $color1 !important;
  }
  .m-select__button {
    background: var(--color-gray);
    > .t-flex-component {
      justify-content: space-between;
    }
  }

  .t-dropdown__content-dropdown &__label-info {
    font-size: 1.2rem;
    float: right;
  }

  .t-dropdown__content-dropdown .a-button--dropdown {
    min-height: 30px !important;
    padding: 5px !important;
    .t-flex-component  {
      width: 100%;
      > span:first-child { text-align: left; }
      .m-select__label-info { 
        text-align: right;
        svg {
          margin-right: 3px;
        }
      }
    }
  }
    

  &--right {
    .m-select__button {
      > .t-flex-component {
        justify-content: flex-end;
      }
    }
    .a-button--dropdown {
     justify-content: flex-end !important;
    }
  }
  &.is-disabled {
    cursor: pointer;
    /* pointer-events: none; */
    .m-select__button {
      pointer-events: none;
      background: $color-light-purple;

      span {
        color: var(--color-dark-gray);
      }
      svg {
        display: none;
      }
    }
  }
  &.is-sm {
    height: 28px;
    .m-select__button {
      padding: 1px 3px;
      span {
        font-size: 1.3rem;
      }
    }
  }
  &.read-only {
    cursor: default;
    /* pointer-events: none; */
    // order: 3;
    @extend .is-disabled;
    .o-copy-dropdown__button {
      background: transparent !important;
    }
  }
  .global-edit & {
    .m-select__button {
      background: var(--color-white);
    }
  }
  &--gray {
    .m-select__button {
      span {
        color: var(--color-dark-gray);
      }

      background: var(--color-gray);
      padding: 0px 10px;
      &:hover {
        color: var(--color-dark-gray);
        background: var(--color-gray);
      }
    }
  }

  &.fullwidth { width: 100%;}
}
