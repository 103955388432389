.m-image-card {
  width: 100%;
  height: 100%;
  background: var(--color-white);
  position: relative;
  border: 4px solid var(--color-gray);
  overflow: hidden;
  color: var(--color-purple);
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    transform-origin: center;
    background-image: url("../../../../_utils/images/transparency.jpeg");
    background-size: 150%;
    ~ span {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;
      cursor: grab;
      transition: 0.3s;
      border-radius: 0 0 0 10px;
    }     
  }
  &--active {
    border-color: var(--color-purple);
  }
  &--dragging {
    cursor: default;
    img {
      cursor: move;
      cursor: grab;
      filter: grayscale(1) opacity(0.8) brightness(0.75);
      ~ span {
        font-size: 90px;
        width: 100px;
        height: 100px;
        font-weight: bolder;
      }
    }
  }
  &--portrait {
    grid-row: span 2;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  
}
