.m-inputs-buttons {
  align-items: center;
  display: flex;
  position: absolute;
  right: 10px;
  top: 105%;
  @include z-index(third);
  &__button {
    box-shadow: 0px 1px 4px black, 0 -1px 1px black;
    height: 38px;
    width: 38px;
    &:first-child {
      margin-right: 6px;
      color: var(--color-red);
      .t-flex-component {
        svg {
          font-size: 17px;
        }
      }
    }
    .t-flex-component {
      svg {
        font-size: 18px;
      }
    }
  }
}
