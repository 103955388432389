.o-details-photo {
  height: calc(100% - 15px);
  padding: 5px 10px; /* 
  border-radius: var(--border-radius);
  box-shadow: 5px 5px 15px 8px rgba(black, 0.07);   overflow: hidden;*/
  animation: opa 1s forwards;
  &__scrollable {
    border-radius: var(--border-radius);
    box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
    margin-top: 20px;
    height: calc(100% - 170px);
    .no-data-photo &{
      height: calc(100% - 90px);
    }
    &-content{
      padding: 10px 15px 0 15px;
    }
  }
  &__data-photos {
    .o-accordion__header {
      .a-button {
        min-height: 30px !important;
      }
    }
    .o-accordion__content {
      min-height: 68px;
      max-height: 140px;
      &-body {
        display: flex;
        justify-content: baseline;
        flex-wrap: wrap;
        align-items: center;
        margin: -10px -25px;
        height: 100%;
        .m-photo-label {
          margin: 10px 25px;
        }
      }
    }
    .o-accordion__header {
      cursor: default;
      &-title {
        color: var(--color-purple);
        font-size: 2rem;
      }
      &:hover {
        background: var(--color-white);
      }
      .a-button {
        min-height: 38px;
      }
    }
  }
  .a-accordion-button {
    width: 30px;
    min-height: 40px;
    padding: 0;
    svg{
      font-size: 20px;
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
  .m-resume-copy {
    margin: 10px 0;
    span {
      padding-bottom: 10px;
      display: block;
      font-size: 1.8rem;
      font-weight: 500;
    }
    &__content {
      color: rgb(75, 75, 75);
      font-size: 1.6rem;
      letter-spacing: 0.3px;
      line-height: 2.3rem;
    }
  }
}
