.v-tasks {
  padding: var(--padding) var(--padding) var(--padding) 0;
  height: 100%;
  &__table-body {
    min-height: calc(100% - 20px);
    height: calc(100% - 20px);
    a:hover {
      text-decoration: underline;
    }
  }
}

.v-task-items {
  position: relative;
  height: 100%;
  padding: calc(var(--padding) / 2) var(--padding) 0px 0px;
  z-index: 98;

  &__task-description {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: calc(var(--padding) / 3) calc(var(--padding) / 2);
    background-color: $color-gray;
    min-height: 30px;
    text-align: justify;
    margin-bottom: 10px;
  }

  &__actions-bar {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-purple);
    z-index: 2;

    &-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      padding: 20px;
    }

    &-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      padding: {
        left: 20px;
        right: 10px;
      }

      &.--delete {
        justify-content: space-between;
      }
    }
  }

  &__table {
    margin-top: 10px;
    height: calc((100% - 120px));
    .--thumbnail {
      max-width: 35px;
      max-height: 35px;
      margin: auto;
    }
  }

  &__table-body {
    min-height: calc(100% - 50px);
    height: calc(100% - 50px);
  }
}
