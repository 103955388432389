.a-task-eta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 6px;
    color: $color-white;
    min-width: 100px;
    min-height: 30px;
    max-height: 30px;
    text-align: center;
    font-size: 1.4rem;
    &.--defeated{ background-color: $color-red; }
    &.--near{ 
        background-color: $color-yellow; 
        color: $color-dark-gray;
    }
    &.--valid{ background-color: $color-blue; }
    &.--done{
        background-color: $color-light-green;
        justify-content: center;
    }
    &.--undefined{ 
        color: $color-purple;
        justify-content: center;
    }
}
