.m-semaphore {
    &__step {
        display: inline-block;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        margin: 10px 20px 10px 0;
        &.--completed {
            background-color: $color-green;
            box-shadow: $color-purple 1px 1px 5px -1px;
        }
    
        &.--current {
            background-color: $color-yellow;
            box-shadow: $color-purple 1px 1px 5px -1px;
        }
    
        &.--queue {
            background-color: $color-gray;
            box-shadow: $color-purple 1px 1px 5px -1px;
        }

    }
    
}
