.Toastify__toast-container {
  min-width: 380px;
  max-width: 400px;
}
.Toastify__toast {
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(black, 0.25);
  min-height: auto;
  padding: 0;
  position: relative;
  &-body{
      padding: 0;
      min-height: auto;
  }
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
  background: lighten($color-blue, 30);
  .Toastify__progress-bar--info {
    background: $color-blue;
  }
}
.Toastify__toast--success {
  background: var(--color-success);
  .Toastify__progress-bar--success {
    background: darken($color-success, 30);
  }
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
  background: var(--color-error);
  .Toastify__progress-bar--success {
    background: darken($color-error, 30);
  }
}
.Toastify__toast-body {
}
