.m-date-range {
    .rdrStartEdge, .rdrInRange, .rdrEndEdge {
        color: $color-purple !important;
    }
    .rdrDayHovered {
        border-color: $color-purple !important;
    }

    &--btn-reset {
        float: right;
        span {
            font-size: 1.4rem !important;
        }
    }
}
