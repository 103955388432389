.a-magnifier {
  width: 100%;
  height: calc(100% - 20px);
  background-image: url("../../../_utils/images/transparency.jpeg");
  background-size: 50%;
  position: absolute;
  @include z-index("third");
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
}
