.o-list {
    background-color: $color-white;
    border-radius: $border-radius;
    width: 100%;
    color: $color-purple;
    &__separator {
        background-color: $color-light-purple;
        border-bottom: 1px solid $color-purple;
        padding: 5px 10px;
        font-weight: 600;
    }
    &__row {
        border-bottom: 1px solid $color-light-purple;
        cursor: pointer;
        padding: 5px 15px;
    }
    :first-child {
        border-radius: $border-radius $border-radius 0px 0px;
    }
    :last-child {
        border-radius: 0px 0px $border-radius $border-radius;
    }
}
