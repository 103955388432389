.o-sidebar {
  @include z-index(toast);
  align-items: center;
  background: var(--color-white);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  bottom: 0;
  box-shadow: 0 4px 41px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding: {
    left: 10px;
    right: 10px;
    bottom: var(--padding);
  }
  position: fixed;
  top: var(--minimal-dimension);
  width: var(--minimal-dimension);
  transition: .3s;
  .a-button--color {
    .is-sm {
      min-width: 2.6rem;
    }
    .a-button__span {
      display: none;
    }
  }
  > .is-sidebar{
   padding-top: 35px;
  }
  &.is-open{
    .o-sidebar__pin{
      svg{
        transform: rotate(-45deg);
      }
    }
  }
  &__pin {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0 0 5px !important;
    min-width: 20px !important;
    width: 20px !important;
    min-height: 20px !important;
    height: 25px !important;
    opacity: 0;
    transition: .3s;
    overflow: visible;
    padding: 0 !important;
  }
  .t-statistics {
    visibility: hidden;
  }
  &:hover:not(.block-hover), &.is-open {
    width: 220px;
    .o-sidebar__pin{
      opacity: 1;
    }
    @include only-sm() {
      width: 240px;
    }
    .a-button--color {
      justify-content: flex-start;
      padding-left: 10px;
      .a-button__span {
        display: block;
      }
    }
  }
  &__powered-by {
    display: flex;
    flex-flow: column;
    max-width: 100%;
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    cursor: pointer;
    justify-self: flex-end;
    svg {
      color: $color-gray
    }
    & .--full {
      background-image: url("../../../_utils/images/logo.png");
      background-position: left;
      background-position-x: 0.9rem;
      background-repeat: no-repeat;
      background-size: 153px auto;
      width: 85%;
      min-height: 26px !important;
      max-height: 26px !important;
      @include md(){
        background-position-x: 1.2rem;
      }
    }
    & .--legend {
      width: 200px;
      overflow-x: hidden;
      height: 1.8rem;
      max-height: 1.8rem;
      color: $color-dark-gray;
      p {
        margin-left: 65px;
      }
    }
    & .--version {
      font-size: 1rem;
      padding: 2px 0px;
      width: 160px;
      overflow-x: hidden;
      height: calc(1rem + 4px);
      max-height: calc(1rem + 4px);;
      color: $color-dark-gray;
      p {
        text-align: right;
      }
    }
  }

  &__logout {
    margin-top: 15px;
    overflow-x: visible !important;
    padding: 0 0 0 calc(var(--padding) / 3) !important;
  }

  &__config {
    justify-self: flex-end;
    width: 100%;
    .a-button {
      padding: 0 0 0 calc(var(--padding) / 3) !important;
    }
  }
 
}

