.o-generate-adaptation-modal {
  margin-top: 15px;
  &__animation {
    align-items: center;
    $bg-color: $color-light-purple + '70';
    background-color: #{$bg-color};
    border-radius: $border-radius;
    color: $color-purple;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: var(--padding);
    .m-icon-drawer__icon {
      font-size: 3rem;
    }
    span {
      font-weight: 500;
    }
  }
}
