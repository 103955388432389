.o-image-slider {
  height: 100%;
  position: relative;
  width: max-content;
  border-radius: var(--border-radius);
  overflow: hidden;
  &.is-home {
    width: 100%;
  }
  &__image {
    height: 100%;
    width: auto;
    object-fit: cover;
    padding: 3px;
    cursor: default;
    border-radius: var(--border-radius);
    .is-home & {
      padding: 0;
      width: 100%;
    }
  }
  &__dots {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
  &.no-image {
    .o-image-slider {
      &__image {
        opacity: 0.4;
        height: 95%;
      }
    }
  }
}
