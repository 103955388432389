.o-bulk-counter {
  background: var(--color-light-purple);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  &__data {
    height: 100%;
    &-item {
      display: flex;
      width: 100%;
      align-items: center;
    }
    &-title {
      position: relative;
      color: var(--color-purple);
      font-size: 1.2rem;
      font-weight: 600;
      top: -7px;
    }
    .a-count-number{
      min-width: 80px;
      text-align: center;
    }
  }
  &__buttons {
    padding-top: 20px;
    display: none;
    justify-content: space-between;
    align-items: center;

    &.visible {
      display: flex;
    }
  }
}
