.o-product-statistics {
 
  &__total {
    display: flex;
    color: var(--color-purple);
    @include md() {
      flex-direction: column;
      padding-left: 8px;
    }
    &-name {
      font-size: 1.6rem;
      margin-right: 20px;
      white-space: nowrap;
      @include md() {
        font-size: 1.8rem;
        margin: 0 0 12px 0;
      }
    }
    &-qty {
      font-size: 1.6rem;
      font-weight: 600;
      @include md() {
        font-size: 2.2rem;
      }
    }
  }
}
