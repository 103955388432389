.a-square-button{
    align-items: center;
    background: var(--color-purple);
    border-radius: var(--border-radius);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 70px;
    padding: 8px;
    position: relative;
    &:disabled{
      background: lighten($color-purple,15);
    }
    @include md(){
      min-width: 73px;
    }
    .a-circle-status{
        height: 7px;
        position: absolute;
        right: 7px;
        width: 7px;
    }
    &__name{
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 3px;
      text-transform: uppercase;
      @include md(){
        font-size: 1.3rem;
      }
      @include xl(){
        font-size: 1.4rem;
      }
    }
    &__status{
      font-size: 1rem;
    }
    &__icon{
      font-size: 2.3rem;
    }
}
