.t-drawer {
    position: fixed;
    z-index: 1000;
    width: 0%;
    height: 100%;
    transition: transform .3s cubic-bezier(.7,.3,.1,1),height 0s ease .3s,width 0s ease .3s;

    .t-drawer__mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: #00000073;
        opacity: 0;
        filter: alpha(opacity=45);
        transition: opacity .3s linear,height 0s ease .3s;
        pointer-events: none;
    }

    &.t-drawer__open {
        .t-drawer__mask {
            height: 100%;
            opacity: 1;
            transition: none;
            pointer-events: auto;
        }
    }
    
    .t-drawer__wrapper {
        box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d, -12px 0 48px 16px #00000008;
    }

    &.t-drawer__right {
        right: 0;
        top: 0;
        width: 0%;
        transition: transform .3s cubic-bezier(.7,.3,.1,1),height 0s ease .3s,width 0s ease .3s;

        &.t-drawer__open {
            width: 100%;
            transition: transform .3s cubic-bezier(.7,.3,.1,1);
        }
        
        .t-drawer__content-wrapper {
            right: 0;
            top: 0;
            height: 100%;
        }
    }

    &.t-drawer__left {
        left: 0;
        top: 0;
        width: 0%;
        transition: transform .3s cubic-bezier(.7,.3,.1,1),height 0s ease .3s,width 0s ease .3s;


        &.t-drawer__open {
            width: 100%;
            transition: transform .3s cubic-bezier(.7,.3,.1,1);
        }

        .t-drawer__content-wrapper {
            left: 0;
            top: 0;
            height: 100%;
            box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d, -12px 0 48px 16px #00000008;
        }
    }

    .t-drawer__content-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .t-drawer__content {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        overflow: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;

        .t-drawer__header {
            font-size: 2.1rem;
            padding: var(--padding);
            box-shadow: inset 0px -1px 1px 0px $color-purple;
        }
    }

    &.t-drawer__wrapper-body {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;
    }
    
    &.t-drawer__header {
        position: relative;
        padding: 16px 24px;
        color: #000000d9;
        background: #fff;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
    }

    .t-drawer__body {
        position: absolute;
        width: 100%;
        height: 80%;
        min-height: 80%;
        flex-grow: 1;
        overflow: auto;
        font-size: 14px;
        line-height: 1.5715;
        word-wrap: break-word;
        padding: var(--padding);
    }
    
    > * {
        transition: transform .3s cubic-bezier(.7,.3,.1,1),box-shadow .3s cubic-bezier(.7,.3,.1,1);
    }
}
