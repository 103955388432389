.o-faq-modal {
  height: calc(100%- 100px);
  width: calc(100%- 150px);
  min-height: 600px;
  min-width: 900px;
  max-height: 1200px;
  max-width: 1600px;

  &__title {
    font-size: 2.8rem;
    font-weight: 500;
    text-align: center;
    padding: 15px 0;
  }
}
