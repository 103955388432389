.o-catalog {
  background: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 41px rgba($color-black, 0.07);
  width: 100%;
  height: 100%;
  &__header {
    display: flex;
    padding: 10px 0;
    margin: 0 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray);
    > .a-button {
      max-width: 40px;
    }
    .t-dropdown {
      width: 50px;
      &__content {
        &-dropdown {
          min-width: 210px;
        }
      }
    }
  }
  &__content {
    height: calc(100% - 370px);

    .o-accordion{
      &__header-title{
        font-size: 1.6rem;
        color: var(--color-dark-gray);
        font-weight: 400;
      }
    }
  }
  &__padding{
    padding: 5px 20px;
  }
  &__info {
    cursor: default;
    padding: 20px 25px;
    color: var(--color-dark-gray);
    border-bottom: 1px solid var(--color-gray);
    .a-paragraph {
      font-size: 17px;
      line-height: 27px;
      margin-bottom: 20px;
    }
  }
  &__data {
    &-spec {
      display: flex;
      justify-content: space-between;
      color: var(--color-black);
      .title {
        font-size: 17px;
        font-weight: 500;
      }
      .data {
        font-size: 16px;
      }
    }
  }
  &__section {
    &-header {
      display: flex;
      margin: 0 20px;
      padding: 20px 0 10px;
      border-bottom: 1px solid var(--color-gray);
      align-items: center;
      justify-content: space-between;
      .title {
        font-weight: 500;
      }
    }
    &-content {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }

  .m-download-row {
    > div:first-child {
      min-width: 200px;
    }
    .--list-resource {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 6px;
      font-size: 1.3rem;
      .a-badge--border-purple {
        max-width: 65px;
        width: 65px;
        padding: 3px 6px;
        span {
          font-size: 1.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@import "./Rows/o-catalog-template-row.scss";
