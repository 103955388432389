.m-table-catalogs {
  height: 100%;
  &__add-new {
    margin: 0 20px;
    padding: 5px 15px 25px;
    color: var(--color-purple);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__title {
    margin: 10px 20px;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      font-size: 2rem;
      font-weight: 500;
      color: var(--color-purple);
    }
    .t-dropdown {
      width: 50px;
      &__content {
        &-dropdown {
          min-width: 210px;
        }
      }
    }
  }
  &__or {
    display: block;
    width: 100%;
    position: absolute;
    bottom: -20px;
    text-align: center;
  }
  &__header {
    display: grid;
    grid-template-columns: 3.5fr 1fr 1fr 1fr;
    justify-items: center;
    height: 40px;
    align-items: center;
    padding: 0 25px 10px;
    span {
      &:first-child {
        justify-self: flex-start;
      }
    }
    .has-selection & {
      padding-top: 30px;
    }
    &-field {
      color: var(--color-dark-gray);
      font-size: 1.5rem;
    }
  }
  &__cells {
    display: grid;
    height: calc(100% - 140px);
    width: 100%;
    .has-selection & {
      padding-top: 30px;
      height: calc(100% - 190px);
    }
    &-content {
      padding: 0 20px;
    }
  }
  &__cell {
    display: grid;
    grid-template-columns: 7fr 1fr;
    width: 100%;
    align-items: center;
    height: 45px;
    background: #f6f6f6;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: 0.3s;
    padding: 0 10px;
    user-select: none;
    > div {
      display: grid;
      grid-template-columns: 3.1fr 1fr 1fr;
      justify-items: center;
      height: 100%;
      align-items: center;
      span {
        &:first-child {
          justify-self: flex-start;
        }
      }
    }
    &-item {
      &.name {
        white-space: nowrap;
        max-width: 85%;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 10px 0;
      }
    }
    .a-button {
      min-width: 35px;
      min-height: 35px;
    }
    .t-dropdown {
      &--button {
        .a-button {
          color: var(--color-dark-gray);
          height: 40px;
          min-height: 40px !important;
          &:hover {
            color: var(--color-purple);
          }
        }
      }
      &__content {
        &-dropdown {
          min-width: 210px;
        }
      }
    }
    &:nth-child(2n) {
      background: transparent;
    }
    &:hover {
      background: #f0f0f0;
    }
    &.drag-hover {
      background: var(--color-purple);
      color: var(--color-white);
      > .a-button {
        color: var(--color-white);
        &:hover {
          background: var(--color-gray);
          color: var(--color-purple);
        }
      }
      .t-dropdown {
        .a-button {
          color: var(--color-white);
          &:hover {
            color: var(--color-purple);
          }
        }
        &__content {
          &-dropdown {
            min-width: 210px;
            .a-button {
              color: var(--color-dark-gray);
            }
          }
        }
      }
    }
  }
}
