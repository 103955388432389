.t-grid-layout {
  padding: {
    top: 25px;
    right: var(--padding);
  }
  display: grid;
  gap: 2rem;
  grid-auto-rows: 330px;
  grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
  &.is-tasks {
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(37rem, 1fr));
  }
  &.is-setting-cards{
    grid-auto-rows: 230px;
    grid-template-columns: 620px;
    gap: 4rem;
    padding: 10px 30px;
    max-width: 100%;
    justify-content: center;
  }
  
  &.is-settings-adaptations-cards {
    grid-auto-rows: 280px;
    grid-template-columns: 1000px;
    gap: 4rem;
    padding: 10px 30px;
    max-width: 100%;
    justify-content: center;
  }

  &.is-settings-layer-cards {
    grid-auto-rows: 280px;
    grid-template-columns: 850px;
    gap: 4rem;
    padding: 10px 30px;
    max-width: 100%;
    justify-content: center;

    @include sm-down() {
      grid-template-columns: 550px;
      grid-auto-rows: 470px;
    }
    @include md() {
      grid-template-columns: 1100px;
    }
  }

  &.is-settings-modules {
    grid-auto-rows: auto;
    grid-template-columns: 900px;
    gap: 4rem;
    padding: 10px 30px;
    max-width: 100%;
    height: auto;
    justify-content: center;

    @include sm-down() {
      grid-template-columns: 550px;
    }
    @include md() {
      grid-template-columns: 1100px;
    }
  }
}
