.v-catalogs{
    height: 100%;
    &__content{
        width: 100%;
        height: 100%;
        padding: calc(var(--padding) + 5px) var(--padding) var(--padding) 0;
        &-component{
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
        }
    }
}
