.o-new-sku-panel {
  min-width: 350px;
  height: 100%;
  .t-border-inputs {
    background: darken($color-gray, 20);
    &.is-focused {
      background: linear-gradient(
        0,
        var(--color-purple) 0%,
        var(--color-blue) 100%
      );
      padding: calc(var(--border-width));
    }
  }
  &__header {
    padding: 15px 15px;
    border-bottom: 1px solid var(--color-gray);
    color: var(--color-purple);
    display: flex;
    align-items: center;
  }
  &__button-upload {
    padding: 15px 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    border-bottom: 1px solid var(--color-gray);
    position: relative;
  }
  &__or {
    display: block;
    position: absolute;
    background: var(--color-white);
    bottom: 0;    
    text-align: center;
    color: var(--color-purple);
    cursor: default;
  }
  &__drop {
    height: 170px;
    grid-column: span 2;
    margin-top: 20px;
    order: 99;
    position: relative;
    &-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    &-title {
      display: block;
      font-size: 1.7rem;
      font-weight: 500;
      margin-bottom: 3px;
      padding-bottom: 10px;
    }
  }
  &__content {
    height: calc(100% - 220px);
    padding-top: 10px;
    &-title {
      color: var(--color-purple);
      font-size: 2.4rem;
      font-weight: 500;
      padding-left: 20px;
    }
    &-inputs {
      margin-top: 10px;
      padding: 0 30px 0 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      column-gap: 25px;
      row-gap: 5px;
      height: 100%;
      .m-input-label {
        &__label {
          padding: 10px 0;
        }
      }
    }
  }
  &__thumb-container {
    order: 99;
    grid-column: span 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    margin: -15px;
    > * {
      margin: 15px;
    }
  }

  &__thumb {
    cursor: pointer;
    border: 1px solid var(--color-purple);
    border-radius: 10px;
    width: 100px;
    position: relative;
    height: 100px;
    .uploading & {
      bottom: 20px;
    }
    &.loading {
      width: 350px;
      > div {
        &:first-child {
          width: 110px;
          height: 100px;
        }
        &:last-child {
          color: var(--color-purple);
          width: 350px;
          background: white;
          height: 100%;
          padding: 0px 20px;
          display: flex;
          .dot-flashing {
            top: 8px;
            left: 20px;
          }
          span {
            font-size: 1.8rem;
            font-weight: 600;
          }
        }
      }
      &:hover {
        background: white;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      display: block;
      object-fit: cover;
    }
    .a-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      transition: 0.3s;
      &:hover {
        background: white;
        transform: scale(1.1);
      }
    }
    span {
      margin-left: 10px;
      color: var(--color-purple);
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: absolute;
    bottom: 20px;
    width: 100%;
    .a-button {
      &:last-child {
        min-height: 35px;
      }
    }
  }
}
