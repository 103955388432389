.u-skeleton {
  background: rgba($color-purple, 0.62);
  color: transparent;
  border-radius: 5px;
  animation: flicker 1.2s alternate-reverse infinite;
  * {
    color: transparent !important;
  }
}


