.v-billing {
  height: 100%;

  &__content {
    padding: 1px var(--padding) calc(var(--padding) /2);
  }

  &__cards,
  &__activity,
  &__history {
    padding: calc(var(--padding)/ 2);
  }

  &__cards {
    align-items: baseline;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-bottom: 15px;
  }

  &__activity {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    padding: 0px;
    margin-top: 20px;
  }

  @include media-down($screen-desktop-sm) {
    &__cards {
      grid-template-columns: repeat(1, 1fr);
    }

    &__activity {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
