.m-input-label {
  &__label {
    display: block;
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 3px;
  }
  &__require{
    font-weight: 400;
    font-size: 14px;
    color: var(--color-dark-gray);
  }
}
