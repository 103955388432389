.o-download-xls-modal {
  border-top: 1px solid var(--color-gray);
  margin-top: 25px;
  width: 600px;
  &__content {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 20px;
    &-text {
      color: var(--color-dark-gray);
      font-size: 1.5rem;
      padding-left: 10px;
      white-space: nowrap;
    }
  }

  &__datamap-selected {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__output {
    background-color: $color-light-purple;
    display: flex;
    justify-content: space-around;
    padding: 20px 10px;
    border-radius: $border-radius;
    > div {
      text-align: center;
    }
    input[type=text] {
      opacity: .1;
      width: 1px;
      height: 1px;
    }
  }

  &_panel-copy-opts {
    background-color: $color-light-purple;
    border-radius: $border-radius;
    padding: 10px;
    > div {
      color: $color-purple;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 7px;
      margin-bottom: 14px;
      p {
        font-weight: 600;
        font-size: 1.4rem;
      }
      span.a-switch  {
        font-weight: 400;
        font-size: 1.6rem;
      }
    }
  } 

  &__buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
