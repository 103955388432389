.m-task-progress {
    width: 80%;
    margin: auto 0;
    &__wrapper {
      
      > * {
        border-radius: 5px !important;
        
        > *{
          background-color: $color-light-green !important;
      
          > span {
            font-size: 1.4rem !important;
            font-weight: 600 !important;
          }
        }
        
      }
      > span {
        color: $color-purple !important;
      }
    }
}
