.o-upload-file {
  border-top: 1px solid var(--color-gray);
  margin-top: 10px;
  width: 520px;
  &__header {
    width: 100%;
    height: 25px;
    display: block;
  }
  &__content {
    width: 100%;
  }
  &__footer {
    display: block;
    margin-top: 10px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .a-button{
      min-height: 30px;
      max-width: max-content;
    }
  }
}
