
/*=============================================
=                 SETTINGS                    =
/// Put here the global vars
=============================================*/

/* Color  */
$color-black: #000000;
$color-blue: #667EE8;
$color-gray: #F6F6F6;
$color-green: #17992C;
$color-purple: #764CA4;
$color-dark-gray: #615e66;
$color-light-green: #53E897;
$color-light-purple: #F1EDF6;
$color-red: #EA402D;
$color-white: #ffffff;
$color-yellow: #FDF655;
$color-success: #E8F5EA;
$color-error: #f5e8e8;
$color-orange: #eba134;
/* Fonts */
$font-primary: 'Poppins', sans-serif;

/* Padding */
$padding-sm: 20px;
$padding-md: 30px;

/* HEIGHT */
$height-misc: 40px;
$height-misc-md: 50px;
/* RESPONSIVE */
 
/* Desktop */
$screen-desktop-xl: 1920px;
$screen-desktop-md: 1440px;
$screen-desktop-sm: 1280px;
$screen-height-xl: 970px;
$screen-height-md: 970px;
$screen-height-sm: 720px;

/* Misc */
$minimal-dimension-md: 70px;
$minimal-dimension-sm: 65px;
$border-radius: 10px;

/* Box shadow */
$shadow: 5px 5px 15px 8px rgba(0, 0 , 0, 0.07);
$shadow-gray: 0px 10px 15px 2px $color-gray;


/*=====  End of SETTINGS               ======*/

