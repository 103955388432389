.u-black { color: $color-black !important}
.u-blue { color: $color-blue !important }
.u-dark-gray { color: $color-dark-gray !important }
.u-error { color: $color-error !important }
.u-gray { color: $color-gray !important }
.u-green { color: $color-green !important }
.u-light-green { color: $color-light-green !important }
.u-light-purple { color: $color-light-purple !important }
.u-purple { color: $color-purple !important }
.u-red { color: $color-red !important }
.u-success { color: $color-success !important }
.u-white { color: $color-white !important }
.u-yellow { color: $color-yellow !important }
