.m-button-color {
  &__backdrop {
    $color: $color-black + "10";
    background-color: #{$color};
    height: 100%;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    @include z-index(modal);
  }
  &__selector {
    position: fixed;
    @include z-index(navigation);
    width: 200px;
    height: 305px;
    top: var(--selector-top);
    left: var(--selector-left);
  }
  .sketch-picker {
    @include z-index(navigation);
  }
  &__square {
    height: 15px;
    width: 15px;
    border: solid 1px $color-dark-gray;
  }
}
