.o-approve-photo-modal {
  border-top: 1px solid var(--color-gray);
  margin-top: 10px;
  padding-top: 20px;
  max-width: 550px;
  width: max-content;
  color: var(--color-dark-gray);
  font-size: 1.7rem;
  &__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__warning {
    margin: 25px 0 10px;
    padding: 10px;
    background: var(--color-gray);
    border-radius: var(--border-radius);
    display: flex;
    color: var(--color-purple);
    span {
      font-size: 1.4rem;
      margin-left: 10px;
      line-height: 1.7rem;
      letter-spacing: 0.5px;
    }
    svg {
      font-size: 3rem;
    }
  }
  &__buttons {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}


