.a-circle-status {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  &.is-complete {
    background: var(--color-green);
  }
  &.is-with-error {
    background: var(--color-red);
  }
  &.is-incomplete {
    background: var(--color-yellow);
  }
  &.is-empty {
    background: darken($color-gray, 30);
  }
}
