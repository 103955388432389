.m-statistics-button {
  background: var(--color-gray);
  border-radius: var(--border-radius);
  min-height: 56px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: lighten($color-purple, 12);
  padding: 15px 20px;
  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__content {
    &-title {
      margin-bottom: 8px;
      font-size: 1.6rem;
      text-transform: uppercase;
      font-weight: 400;
    }
    &-qty {
      margin-left: 10px;
      font-size: 1.9rem;
      font-weight: 600;
      color: var(--color-purple);
    }
  }
  &:hover {
    background: lighten($color-dark-gray, 55);
  }
}
