.t-toolbar {
  align-items: center;
  display: flex;
  height: var(--height-misc);
  position: relative;
  justify-content: space-between;
  background: var(--color-white);
  padding-right: var(--padding);
  width: 100%;
  @include z-index(modal);
  &__details {
    top: 5px;
  }
  &::before {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 100%;
    right: 30px;
  }
  &::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 50px;
    background: var(--color-white);
    left: 0;
    bottom: 100%;
    right: 0;
  }
  .has-filter & {
    &::before {
      display: none;
    }
  }
  &__photos,
  &__copy,
  &__task,
  &__details {
    padding: 10px 20px;
    height: max-content;
    width: 100%;
    &::before {
      display: none;
    }
  }
  &__catalogs, &__property {
    &::before {
      display: none;
    }
  }
}
