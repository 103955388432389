.m-active-card {

  &__switch{
    color: var(--color-purple);
    font-weight: 500;
    font-size: 1.7rem;
  }
  &__division {
    color: var(--color-purple);
    font-weight: lighter;
  }
}
