.m-input-search {
  position: relative;
  &__input {
    font-size: 1.6rem;
    padding: 0 40px;
    min-width: 400px;

    :focus + .m-input-search__autocomplete {
      display: block;
    }
  }
  
  &__autocomplete {
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    height: 200px;
    max-height: 200px;
  }

  &__icon {
    color: var(--color-dark-gray);
    font-size: 2rem;
    left: 10px;
    position: absolute;
    top: calc(var(--height-misc) / 2);
    transform: translateY(-50%);
    .is-focused & {
      color: var(--color-purple);
    }
  }
  &__filters {
    position: absolute;
    right: 2px;
    top: calc(var(--height-misc) / 2);
    transform: translateY(-50%);
    color: var(--color-purple);
    transition: 0.3s;
    svg {
      &:last-child {
        font-size: 1.5rem;
        margin-left: -10px;
        transition: .3s;
      }
    }
    &.is-open {
      background: rgba($color-blue, 1);
      border-radius: 0  var(--border-radius) var(--border-radius) 0;
      *{
          color: var(--color-white);
      }
   
      svg {
        &:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }
  @include md() {
    width: 550px;
  }
}
