.m-filter-date-range {

    &__backdrop {
        height: calc(100vh - 130px);
        display: none;
        position: absolute;
        left: 0px;
        right: 0px;
        $bg-color: $color-gray + '90';
        background-color: #{$bg-color};
        &.is-open {
            display: block;
        }
    }

    &__container {
        float: right;
        background-color: $color-white;
        justify-content: flex-end;
        width: 700px;
        height: auto;
        padding: var(--padding);
    }

    &__collapse {
        margin-bottom: 10px;
    }
}
