.a-dimension-preview {
  padding-left: 10px;
  overflow: visible;
  &__square {
    position: absolute;
    display: block;
    min-width: 40px;
    min-height: 40px;
    height: var(--height);
    width: var(--width);
    border: 1px solid $color-purple;
    transition: 0.1s;
    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      right: 0;
      left: 0;
      height: 1px;
      background: darken($color-gray, 20);
      transition: 0.1s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -5px;
      width: 1px;
      background: darken($color-gray, 20);
      transition: 0.1s;
    }
    &-height,
    &-width {
      display: block;
      position: absolute;
      color: var(--color-dark-gray);
      font-size: 1.2rem;
      width: max-content;
      cursor: default;
    }
    &-height {
      white-space: nowrap;
      width: 0;
      transform: rotate(-90deg);
      top: 50%;
      left: -12px;
    }
    &-width {
      transform: translate(-50%);
      bottom: -20px;
      left: 50%;
    }
  }
  .--transparent {
    background-size: 140px 140px;
    background-image: url("../../../_utils/images/transparency.jpeg");
    background-repeat: repeat;
    height: var(--height);
    width: var(--width);
  }

  .--overlayer {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    height: var(--height);
    min-height: var(--height);
    min-width: var(--width);
    width: var(--width);
  }

  .--solid {
    background-color: var(--color0),
  }

  .--radial {
    background: radial-gradient(var(--color0), var(--color1));
  }

  &__content-margin {    
    border: 2px dotted #444444;
    background-color: transparent;
    left: calc(var(--l3));
    min-height: calc(var(--height) - (var(--l0) + var(--l2)));
    min-width: calc(var(--width) - (var(--l1) + var(--l3)));
    height: calc(var(--height) - (var(--l0) + var(--l2)));
    width: calc(var(--width) - (var(--l1) + var(--l3)));
    position: absolute;
    top: var(--l0);
    transition: 0.1s;
  }
}
