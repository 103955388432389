.o-removal-quota-modal {
  @include modal-styles;
  min-height: 100px;
  min-width: 550px;
  &__content {
    $bg-color: $color-light-purple + '70';
    align-items: center;
    background-color: #{$bg-color};
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 20px 25px;
    > div {
      text-align: center;
      h2 {
        font-weight: 400;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
