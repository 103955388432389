.o-settings-sidebar{
  align-items: center;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 5px 5px 15px 8px rgba(black, 0.07);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px 10px 25px;
  width: 100%;

  .--submenu {
    .t-flex-component {
      display: flex;
      justify-content: space-between;
      width: inherit;
    }

    span {
      padding-left: 20px;
    }
  }
}
