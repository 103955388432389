.a-textarea {
  background-color: $color-gray;
  border: 2px solid transparent !important;
  border-radius: $border-radius;
  resize: none;
  padding: 5px;
  width: 100%;
  &:focus-visible {
    outline: 0px;
  }
  &:focus {
    border-color: $color-purple !important;
  }
  
  &:disabled {
    background-color: $color-light-purple;
  }
  &:disabled, &:read-only {
    cursor: not-allowed;
  }
}
