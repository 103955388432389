.m-property-channel{
    padding: 10px 5px;
    border-bottom: 1px solid #764CA415;
    display: flex;
    justify-content: space-between;
    &__title{
        color: var(--color-purple);
        letter-spacing: 1px;
        font-size: 1.7rem;
        white-space: nowrap;
    }
}
