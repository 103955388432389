.mod-syncfonia-update-gtin-modal {
  $bg-color: $color-light-purple + '70';
  color: $color-purple;
  min-width: 380px;
  padding-top: 30px;
  &__controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  &__processing {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .--percent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      > div {
        min-width: 30%;
      }
    }
  }

  &__done {
    background-color: #{$bg-color};
    border-radius: $border-radius;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    gap: 25px;
    padding: 20px;
    > div {
      display: flex;
      justify-content: space-between;
    }
    .--success { 
      display: flex;
      align-items: center;
      gap: 10px;
      > div:first-child {
        background-color: $color-green;
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }
    .--warning { 
      display: flex;
      align-items: center;
      gap: 10px;
      > div:first-child {
        background-color: $color-yellow;
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }
  }

  .--option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    :first-child {
      flex: "1";
    }
    :last-child {
      flex: none;
    }
  }

  .--loading {
    background-color: #{$bg-color};
    border-radius: $border-radius;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 120px;
    > div:first-child, > div:last-child { 
      align-items: center;
      display: flex;
      gap: 5px;
      justify-content: center;
    }

    > div:first-child {
      width: 40%;
    }

    > div:last-child {
      display: flex;
      flex-direction: column
    }
  }

  @include modal-buttons;
}
