.o-resource-done-process {
    $bg-color: $color-light-purple + '70';
    background-color: #{$bg-color};
    border-radius: $border-radius;
    color: $color-purple;

    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: var(--padding) 0;

    > div {
        display: flex;
        flex-direction: column;
        flex: 1;
        cursor: pointer;

        > svg {
            font-size: 4.5rem;
            margin: 10px auto;
        }
        > input {
            width: 1px;
            height: 1px;
            position: absolute;
            opacity: 0.1;
        }

        
    }
    &__copy-legend {
        position: absolute;
        text-align: center;
        margin: -4px 44px;
    }
}
