.o-upload-images {
  border-top: 1px solid var(--color-gray);
  margin-top: 10px;
  min-width: 350px;
  max-width: 470px;
  padding-top: 20px;
  &__content {
    .o-upload-drop, .upload-bulk-images__uploading {
      min-height: 160px;
    }
  }
  &__next-previous {
    display: flex;
  }
  &__thumb {
    align-items: center;
    min-width: 0;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.4s;
    position: relative;
    &-btn {
      position: absolute;
      right: -2px;
      top: -2px;
      width: 40px !important;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      transition: 0.3s;
      &:hover {
        background: white !important;
        transform: scale(1.1);
      }
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -10px;
      & > * {
        margin: 10px;
      }
    }
    .uploading & {
      bottom: 20px;
    }
    &.loading {
      width: 350px;
      > div {
        &:first-child {
          width: 110px;
          height: 100px;
        }
        &:last-child {
          color: var(--color-purple);
          width: 350px;
          background: white;
          height: 100%;
          padding: 0px 20px;
          display: flex;
          .dot-flashing {
            top: 8px;
            left: 20px;
          }
          span {
            font-size: 1.8rem;
            font-weight: 600;
          }
        }
      }
      &:hover {
        background: white;
      }
    }
    &:hover {
      background: rgba($color-purple, 0.2);
    }
    img {
      width: 100px;
      height: 100px;
      border: 1px solid var(--color-purple);
      border-radius: 10px;
      display: block;
      object-fit: cover;
    }
    span {
      margin-left: 10px;
      color: var(--color-purple);
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__footer {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
