.m-sorter {
  display: grid;

  &__down, &__up {
    cursor: pointer;
    display: flex; 
    justify-content: center;
    height: 12px;
    width: 30px;
    overflow: hidden;
    svg {
      opacity: 0.3;
      font-size: 1.4rem;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }

    &:first-child {
      svg {
        margin-top: -3px;
      }
    }

    &.--disabled {
      &:hover svg {
        opacity: 0.3;
        font-size: 1.4rem;
      }
    }
  }
}
