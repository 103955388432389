.a-hr  {
  width: 100%;
  text-align: center;
  $color-line: $color-purple + "70";
  background-image: linear-gradient(to right, $color-white, #{$color-line}, $color-white);
  height: 1px;
  overflow: visible;
  margin-top: 2rem;
  margin-bottom: 2rem;

  span {
    position: absolute;
    background: $color-white;
    padding: 3px 7px;
    text-align: center;
    color: var(--color-purple);
    font-size: 2rem;
    margin-top: -1.3rem; 
  }
}
