.o-uploading-file {
  background: rgba($color-purple, 0.2);
  border-radius: var(--border-radius);
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--success {
    .o-uploading-file__icons {
      &-lupa {
        display: none;
      }
      &-ready {
        display: block;
      }
      &-lapiz {
        display: none !important;
      }
    }
  }
  &--failure {
    .o-uploading-file__icons {
      &-lupa {
        display: none;
      }
      &-times {
        display: block;
      }
      &-lapiz {
        display: none !important;
      }
    }
  }

  &__icons {
    position: relative;
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-lupa {
      position: absolute;
      animation: circle 3s linear infinite;
      &.lapiz {
        left: 50px;
        top: 10px;
      }
    }
    &-times {
      display: none;
      position: absolute;
    }
    &-ready {
      position: absolute;
      display: none;
    }
    &-lapiz {
      position: absolute;
      animation: circle 3s linear infinite;
      display: none;
      z-index: 4;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 55%;
    &-header {
      display: flex;
      align-items: center;
    }
    &-status {
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--color-purple);
      margin-bottom: 17px;
      margin-right: 20px;
      &.success {
        color: var(--color-green);
      }
      &.failure {
        color: var(--color-red);
      }
    }
    &-file-name {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.5rem;
      max-height: 50px;
      overflow: hidden;
    }
    &-totals {
      display: flex;
      flex-direction: column;
      font-size: 1.8rem;
      line-height: 2.8rem;
      span {
        span {
          color: var(--color-purple);
        }
      }
    }

  
  }
  &__content {
    //TODO refactorizar esto
    &-item {
      animation: opa 1s forwards;
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 10px;
      margin-bottom: 10px;
    }
    &-qty {
      color: var(--color-purple);
      font-size: 2.5rem;
      text-align: center;
    }
    &-text {
      color: var(--color-dark-gray);
      font-size: 1.7rem;
      padding-left: 10px;
      white-space: nowrap;
    }
  }
}
