.o-new-task-form {
  &__flex-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    > * {
      flex: 0 0 calc(45%);
    }
  }

  &__calendar-container {
    position: absolute;
    top: -150px
  }

  @include modal-buttons;
  &__buttons {
    margin-top: 50px;
  }
}
