.a-magnifier-image {
  height: 100%;
  position: relative;
  width: calc(100% - 12px);
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7rem;
  &__image {
    width: auto;
    object-fit: cover;
    background-size: 50%;
    vertical-align: middle;
    margin: auto;
    cursor: url("../../../_utils/images/Lupa.png"), auto;
    background-image: url("../../../_utils/images/transparency.jpeg");
    border: 1px solid rgba($color: $color-purple, $alpha: 1);
    .is-home & {
      padding: 0;
      width: 100%;
    }
    &.--is-empty {
      cursor: default;
      background-image: none;
      border: none;
    }
  }
  
  &__all {
    &--landscape {
      width: 100%;
      height: auto;
    }
    &--square{
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &.is-hovered {
    border: 2px solid var(--color-purple);
  }
  &.without-image{
    .a-magnifier-image{
      &__image{
        max-width: 100%;
        max-height: 100%;
        transform: translateX(-6%);
        cursor: default;
        background: none;
      }
    }
  }
}
