.t-layer-card {
  border-radius: $border-radius;
  box-shadow: $shadow;
  display: inline;
  overflow: hidden;
  width: 100%;

  &__header {
    background-color: $color-light-purple;
    color: $color-purple;
    height: 38px;
    min-height: 38px;
    min-width: 100%;
    padding: 0px 15px 0px 10px;
    > *:first-child {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    > *:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
    }
    .--key { 
      background-color: $color-white;
      border: 1px solid $color-purple;
      border-radius: $border-radius;
      font-weight: 600;
      padding: 1px 10px;
      width: auto;
    }
    .--name { height: fit-content; }
    button span { 
      color: $color-purple;
      font-size: 1.4rem;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: calc(100% - 55px);
    padding: 10px 20px;
    > div {
      display: flex;
      width: 50%;
      &:first-child {
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-around;
        @include sm() {
          border-right: 1px solid;
          border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(50, 50, 50, 0.4), rgba(255, 255, 255, 0)) 1 100%;
        }
      }
      
    }
    @include sm-down() {
      flex-direction: column;
      gap: 20px;
      > div {
        width: 100%;
      }
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;

    .--preview-type {
      color: $color-purple;
      display: flex;
      font-size: 1.1rem;
      justify-content: center;
      gap: 8px;
      div {
        border: 1px solid $color-purple;
        border-radius: 6px;
        cursor: pointer;
        height: 20px;
        padding: 2px 5px;
        width: 20px;
        &.--selected {
          color: $color-white;
          background-color: $color-purple;
        }
      }
    }
  }

  &__config {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    height: 180px;
    gap: 15px;
  }

  &__dimensions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 150px;
    .--form-label {
      display: flex;
      flex-direction: column;
      font-size: 1.3rem;
    }
    .--form-control {
      align-items: center;
      display: flex;
      font-size: 1.3rem;
      flex-direction: row;
      gap: 5px;
      .t-border-inputs {
        width: 78px;
        textarea, span {
          font-size: 1.3rem;
          height: 20px;
        }
      }
      label {
        width: 100%;
        font-size: 1.3rem;
      }
    }
  }

  &__switches {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1.3rem;
    gap: 7px;
    .--title {
      font-weight: 500;
    }
    > div {
      display: flex; 
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .a-switch {
        align-items: center;
        color: $color-black;
        display: flex;
        flex-direction: row;
        font-size: 1.3rem;
        font-weight: 400;
        gap: 7px;
      }
      .--preview-type {
        border: 1px solid $color-purple;
        border-radius: 6px;
        cursor: pointer;
        font-size: 1.1rem;
        height: 20px;
        padding: 3px 6px;
        text-align: center;
        width: 20px;     
      }
    }
  }

  &__ai-settings {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__channel-section {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    padding: 10px 0px 10px 20px;
    width: 100%;
    .--header {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 1.3rem;
      font-weight: 500;
      height: auto;
      justify-content: space-between;
      width: 100%;
      .a-button__span {
        font-size: 1.3rem;
      }
    }
    .--tags-container {
      background-color: $color-gray;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 5px;
      height: 70px;
      justify-content: flex-start;
      padding: 6px 10px;
      width: 100%;
    }
    .--tag {
      background-color: $color-purple;
      border-radius: 10px;
      color: $color-white;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: 500;
      height: fit-content;
      padding: 3px 5px;
      width: fit-content;
    }

    .--adaptations {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      .--form-control {
        display: flex;
        flex-direction: column;
        font-size: 1.3rem;
      }
      .--form-label {
        font-weight: 500;
      }

      > div:first-child {
        width: 35%;
      }
      .--help-text {
        color: $color-dark-gray;
        font-size: 1.3rem;
        text-align: justify;
        width: 60%;
      }
    }
  }
}
