.m-toolbar {
  position: absolute;
  height: 60px;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-purple);
  z-index: 2;
  &-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    padding: 20px;
  }
  &-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: {
      left: 20px;
      right: 10px;
    }
    &.--delete {
      justify-content: space-between;
    }
  }
}
