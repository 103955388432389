.t-dropdown {
  width: 100%;
  &.is-active {
    .t-dropdown__content-dropdown {
      display: block;
      hr {
        border-top: 1px solid rgba($color-dark-gray, 0.45);
      }
    }
    .t-dropdown__mask {
      @include z-index(toast);
      height: 100%;
      background: rgba($color-black, 0.1);
      position: fixed;
      left: var(--minimal-dimension);
      top: var(--minimal-dimension);
      width: 100%;
    }
    .t-dropdown__content {
      @include z-index(navigation);
    }
  }
  &__content {
    position: relative;
    &-dropdown {
      display: none;
      background: white;
      position: fixed;
      padding: 0 5px;
      border-radius: 10px;
      top: calc(var(--top) + 10px);
      right: calc(var(--right));
      min-width: var(--min-width);
      max-height: 190px;
      width: max-content;
      z-index: 99999;
      .t-flex-component {
        padding: 5px 0;
        gap: 5px;
      }
    }
  }

  &.is-active {
    .a-button--primary {
      svg {
        transform: rotate(180deg) !important;
      }
      &:hover {
        background: #fcfbfd;
      }
    }
  }
  &.is-copy {
    svg {
      transition: 0.3s;
    }
    .a-button--dropdown {
      // min-height: max-content !important; //TODO arreglar
      height: 30px !important;
      font-size: 1.6rem !important;
      justify-content: space-between !important;
      border-radius: 5px !important;
    }
    &.is-active {
      svg {
        transform: rotate(180deg) !important;
      }
    }
  }
  &--header {
    .t-dropdown__content-dropdown {
      min-width: 300px;
      &::before {
        content: "";
        border-style: solid;
        border-width: 6px 4px;
        border-color: transparent transparent white transparent;
        bottom: 100%;
        position: absolute;
        right: 7%;
      }
    }
  }
}
