.v-layers {
  width: 100%;
  height: 100%;
  &__content {
    padding: {
      left: 20px;
      right: 0;
    }
  }
}
