.o-share-modal {
  margin-top: 10px;
  border-top: 1px solid rgba($color-black, 0.45);
  width: 400px;
  &__title {
    color: var(--color-purple);
    font-size: 1.8rem;
    padding: 10px 0;
    display: block;
    margin-top: 10px;
  }
  .m-input-label {
    color: var(--color-purple);
    font-size: 1.8rem;
    margin-top: 14px;
    font-weight: 400;
  }
  &__list {
    &-content {
      padding: 10px 15px 10px 0;
    }
  }
  &__shared {
    height: 70px;
    display: flex;
    margin-bottom: 10px;
    &-content {
      padding: 15px 10px;
      display: inline-flex;
      min-width: 100%;
      border-top: 1px solid var(--color-gray);
      .a-avatar {
        margin-left: -5px;
        border: 2px solid var(--color-white);
        width: 40px;
        height: 40px;
        transition: 0.3s;
        &:hover {
          position: relative;
          @include z-index(third);
          transform: scale(1.3);
        }
      }
    }
  }
}
