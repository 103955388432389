.o-add-adaptation-modal {
  margin-top: 10px;
  border-top: 1px solid rgba($color-black, 0.45);
  padding-top: 10px;
  min-width: 600px;
  &__header,
  &__footer {
    display: flex;
    flex-wrap: wrap;
  }
  &__header {
    margin-bottom: 15px;
    .m-select {
      min-width: 100%;
      &__button {
        background: var(--color-gray) !important; /* TODO */
      }
    }
    &-input {
      position: relative;

      .m-input-text-r {
        padding: 5px 10px;
        padding-left: 38px;
        text-align: right;
        min-width: 180px;
      }
    }
  }
  &__info-photo {
    background: var(--color-gray);
    border-radius: var(--border-radius);
    margin: 12px 0;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .--preview {
      width: 150px;
      max-width: 150px;
      height: 150px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .--data {
      width: 400px;
      max-width: 400px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      > * {
        flex: 1;
        flex-basis: 31.5%;
        max-width: 31.5%;
        margin: 5px 3px;
      }
    }
  }
  &__animation {
    align-items: center;
    $bg-color: $color-light-purple + '70';
    background-color: #{$bg-color};
    border-radius: $border-radius;
    color: $color-purple;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: var(--padding);
    .m-icon-drawer__icon {
      font-size: 3rem;
    }
    span {
      font-weight: 500;
    }
  }

  &__sku-input {
    background-color: $color-light-purple;
    .m-input-text-r {
      text-align: right;
      background-color: $color-light-purple;
    }
    > * { width: 50%;}
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius;
    flex: 3 !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem;
    color: var(--color-purple);
    font-weight: 600;
    padding: 0px 0px 0px 10px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .a-button {
      font-weight: 500;
      &:first-child {
        font-weight: 400;
        span {
          font-size: 15px;
        }
      }
      min-height: 32px;
    }
  }
  .--channel-option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    div:first-child {
      width: 90px;
      max-width: 90px;
    }

    div:first-child .a-badge--border-purple span, div:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
