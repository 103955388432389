.a-avatar {
    background: darken($color-blue,10);
    width: calc(1.5rem * 2);
    height: calc(1.5rem * 2);
    display: block;
    border-radius: 50%;
    overflow: hidden;
    color: var(--color-white);
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span{
        letter-spacing: 0px;
        font-size: 1.5rem;
        transform: skewX(-2deg);
    }

    &.--dashed { border: 2px dashed $color-blue }
    &.--empty { background-color: $color-gray }
    &.--transparent { background-color: transparent; }
}
