.t-product-canvas-editor {
  @include z-index(canvasEditor);
  background-color: $color-white;
  width: calc(100%);
  height: calc(100vh - 75px);
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  box-shadow: $shadow;
  top: 0px;

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;

    .--data {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    }

    .--product-key {
      color: $color-purple;
      display: flex;
      font-size: 1.8rem;
      font-weight: 500;
      align-items: center;
    }
    .--index {
      color: $color-purple;
      display: flex;
      align-items: center;
      font-size: 1.3rem;
    }

    > div {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      // max-width: 50%;
      // min-width: 50%;
      gap: 0px;

      &:first-child {
        justify-content: space-between;
        gap: 10px;
      }
      
      &:last-child {
        justify-content: flex-end;
        gap: 10px;
      }
    };
  }
}
