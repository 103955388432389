.a-property {
  padding: 8px;
  border-bottom: 1px solid rgba($color-purple, 0.4);
  &--space{
    border: none;
  }
  &__header{
    margin-bottom: 6px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    .a-property--header & {
      align-items: flex-start;
    }
    .a-property--description & {
      .a-property__child {
        width: 100%;
      }
    }.a-property--space &{
      .a-property{
        &__child{
          width: 100%;
        }
      &__header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }}
    }
  }
  &__name {
    font-size: 1.4rem;
    color: var(--color-dark-gray);
    font-weight: 500;
    margin-bottom: 2px;
  }
  &__description {
    margin-bottom: 4px;
    font-size: 1.1rem;
    color: var(--color-dark-gray);
  }
}
