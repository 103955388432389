.m-bullets{
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 1px;
    top: 29px;
    &__dots-area {
      height: 2.28rem;
      display: flex;
      width: 2.28rem;
      align-items: center;
      justify-content: center;
      & > div {
        background: rgb(114, 114, 114);
        width: 30%;
        height: 30%;
        border-radius: 50%;
      }
    }
}
