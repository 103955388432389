.mod-multivende-settings-card {
  border-radius: $border-radius;
  &__header {
    color: $color-purple;
    background-color: $color-light-purple;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;

    > div {
      display: flex;

      &:first-child {
        align-items: center;
        gap: 10px;
      }
      &:last-child {
        justify-content: flex-end;
        gap: 15px;
      }
    }
    .a-badge--border-purple {
      height: 28px;
      border-radius: $border-radius;
      > span {
        align-items: center;
        display: flex;
        justify-items: center;
        gap: 5px;
      }
    }
  }

  .--logo { 
    align-items: center;
    background-color: $color-white;
    display: flex;
    height: 25px;
    width: auto;
  }
  .--name {
    font-weight: 600;
  }
  &__content {
    min-height: 100px;
    height: auto;
    padding: $padding-md;
    .--mod-config {
      display: flex;
      gap: 0px;
      justify-content: space-between;
      padding-bottom: $padding-md;

      > div {
        display: flex;
        &:first-child {
          max-width: calc(100% - 230px);
          min-width: calc(100% - 230px);
        }
        &:last-child {
          display: flex;
          width: 230px ;
          max-width: 230px;
          .t-form-control__input-control { justify-content: center;}
          .t-flex-component {
            justify-content: center
          }
          .a-button { min-width: 150px;}
          .m-form-label {
            display: flex;
            justify-content: center;
          }
        }
      }

      @include sm-down() {
        flex-direction: column;
        gap: 20px;
        > div {
          &:first-child {
            min-width: 100%;
            width: 100%;
          }
          &:last-child {
            .t-form-control__input-control { justify-content:left;}
          }
        }
      }
    }

    .--mod-sync {
      align-items: center;
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      justify-content: space-between;
      display: flex;
      padding-bottom: $padding-md;
      padding-top: $padding-md;
      > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 50%; 
        > div {
          &:first-child { align-items:normal;}
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 50%; 
        }
      }
    }

    .--mod-config-aditional {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      display: flex;
      justify-content: space-between;
      padding-top: $padding-md;
    }
  }
}
