.m-photo-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  cursor: default;
  &__title {
    padding-bottom: 10px;
  }
  &__data {
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--color-dark-gray);
  }
  &__color {
    width: 20px;
    height: 20px;
    border-radius: var(--border-radius);
  }
  &.is-color {
    .m-photo-label__title {
      padding-bottom: 7px;
    }
    align-items: center;
  }
}
