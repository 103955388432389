.o-new-task-modal {
  @include modal-styles;

  align-items: stretch;
  > * {
    min-height: 100% !important;
  }
  
  &__form-type {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 15px;
    justify-content: center;
    height: 100%;
    padding: 10px;
    padding-bottom: 20px;
    > * {
      width: 100%;
      justify-content: center;
    }
  }
  
}
