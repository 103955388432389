$z-index: (
  navigation: 200,
  toast: 99,
  canvasEditor: 98,
  modal: 97,
  section: 97,
  third: 3,
  second: 2,
  first: 1,
);

@function z-index($key) {
    @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
