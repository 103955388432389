.o-copy-dropdown {
  position: relative;
  order: 2;
  &__label {
    display: block;
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 3px;
  }
  &__button {
    background: var(--color-white);
    padding: 5px !important;
    min-height: max-content !important; //TODO arreglar
    height: 33px !important;
    font-size: 1.6rem !important;
    justify-content: space-between !important;
    border-radius: 10px !important;
    .t-flex-component {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: var(--color-black);
      svg {
        color: var(--color-purple);
        font-size: 18px;
      }
    }

    &:hover {
      background: var(--color-white) !important;
    }
  }

  &.is-disabled {
    cursor: pointer;
    .t-dropdown {
      pointer-events: none;
    }
    .o-copy-dropdown__button {
      background: var(--color-gray);
      .t-flex-component {
        color: rgb(75, 75, 75) !important;
      }

      svg {
        display: none;
      }
    }
  }
  &.read-only {
    cursor: default;
    pointer-events: none;
    order: 3;
    @extend .is-disabled;
    .o-copy-dropdown__button{
      background: transparent !important;
    }
  }
  &__buttons {
    align-items: center;
    display: flex;
    position: absolute;
    right: 10px;
    top: 105%;
    z-index: 99;
    &-button {
      &:first-child {
        margin-right: 6px;
        color: var(--color-red);
        .t-flex-component {
          svg {
            font-size: 17px;
          }
        }
      }
      .t-flex-component {
        svg {
          font-size: 18px;
        }
      }
      box-shadow: 0px 1px 4px black, 0 -1px 1px black;
      height: 38px;
      min-height: 38px !important; /* TODO cambiar al refactorizar */
      min-width: 38px !important;
      width: 38px;
    }
  }
}
