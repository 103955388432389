.t-flex-table {
  &__container {
    display: block;
    height: 100%;
    min-height: 100%;
  }

  &__body {
    display: grid;
    min-height: 100px;
  }

  &__header, &__row {
    display: grid;
    flex-wrap: wrap;
  }

  &__header {
    color: $color-purple;
    font-weight: 600;
    $color-line: $color-purple + '40';
    border-bottom: #{$color-line} solid 1px;
    padding: 5px;

    &:nth-child(odd) {
      background-color: $color-gray;
    }
    > * {
      padding: calc(var(--padding)  / 3) 0;
    }

  }

  &__row {
    margin-bottom: 5px;
    padding: 10px 5px;
    display: grid;
    align-items: center;

    &:nth-child(even) {
      background-color: $color-gray;
    }
    &.--empty {
      text-align: center;
      justify-content: center;
    }
  }

 
}
