.m-square-edit {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border: 1px solid $color-purple;
  display: block;
  height: 120px;
  width: 120px;

  @include sm() {
    height: 150px;
    width: 150px;
  }

  &:hover .--edit-btn, &:hover .--edit-btn-filled { opacity: 1 !important}

  &.--focused { cursor: pointer; }

  .--preview-height,
  .--preview-width {
    display: block;
    position: relative;
    color: var(--color-dark-gray);
    font-size: 1.2rem;
    width: max-content;
    cursor: default;
  }

  .--preview-height {
    white-space: nowrap;
    width: 0;
    transform: rotate(-90deg);
    top: 75px;
    left: -8px;
  }

  .--preview-width {
    left: calc(50% - 5px);
    top: 120px;
    transform: translate(-50%);
    @include sm() {
      top: 150px;
    }
  }

  .--empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-around;
    padding: 0 10px;
    position: relative;
    top: -28px;
    width: 100%;
    
  }
  

  .--empty-legend {
    font-size: 1.1rem;
    height: fit-content;
    width: 100%;
  }

  .--create-legend {
    font-size: 1.1rem;
    height: fit-content;
    width: 100%;
    p { 
      font-weight: 600;
      margin-bottom: 10px;
    }
    @include sm() {
      font-size: 1.4rem;
    }
  }

  .--edit-btn-filled {
    background-color: $color-blue;
    border-radius: 5px;
    color: $color-white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    margin: auto;
    opacity: 0;
    padding: 4px 6px;
    position: relative;
    top: 35px;
    @include sm-down() {
      top: 22px;
    }
    width: fit-content;
  }

  .--edit-btn {
    background-color: $color-blue;
    border-radius: 5px;
    color: $color-white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    margin: auto;
    opacity: 0;
    padding: 4px 6px;
    position: relative;
    top: -110px;
    width: fit-content;
  }
}
