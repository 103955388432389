.t-watermarker-canvas-editor {
  @include z-index(canvasEditor);
  background-color: $color-white;
  width: calc(100% - 20px);
  height: calc(100vh - 75px);
  padding-right: 10px;
  position: absolute;
  box-shadow: $shadow;
  top: 0px;

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;

    .--data {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    }

    .--adaptation-key, .--adaptation-name {
      color: $color-purple;
      display: flex;
      font-size: 1.8rem;
      font-weight: 500;
      align-items: center;
    }
    > div {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 0px;

      &:first-child {
        justify-content: space-between;
        gap: 10px;
      }
      
      &:last-child {
        justify-content: flex-end;
        gap: 10px;
      }
    };
  }
}
