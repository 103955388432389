.v-screen-not-compatible {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
  margin: auto;
  max-width: 500px;

  &__image {
    max-width: 450px;
    width: calc(100% - 20px);
  }

  &__title {
    font-size: 3rem;
    text-align: center;
    font-weight: 500;
  }

  &__desc {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 300;
  }
}
