.m-image-dots {
  align-items: center;
  background: rgba($color-white, 0.85);
  border-radius: var(--border-radius);
  display: flex;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
  &__dots {
    background: var(--color-white);
    border-radius: 50%;
    border: 1px solid var(--color-purple);
    height: 8px;
    width: 8px;
    &.is-active{
        background: var(--color-purple);
    }
  }
  svg{
      height: 35px;
      padding: 3px 7px;
      cursor: pointer;
      width: 35px;
      color: var(--color-purple);
  }
}
