.m-notification-button{
    cursor: pointer;
    position: relative;
    &__count{
        position: absolute;
        right: -2px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-white);
        border-radius: 50%;
        height: 12px;
        width: 12px;
        font-size: 1rem;
        font-weight: 600;
        color: var(--color-blue);
        box-shadow: 0px 1px 1px var(--color-white), 0px -1px 1px var(--color-white);
        @include md(){
            font-size: 1.1rem;
        }
    }
    &:hover{
        background: lighten($color-blue, 1);
    }
    
}
