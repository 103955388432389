.o-filter {
  height: 0;
  transition: 0.3s;
  margin-right: var(--padding);
  border-bottom: 1px solid rgba($color-purple, 0);
  position: relative;
  overflow: hidden;
  width: 100%;
  &.has-drop-paste {
    display: flex;
    > div:first-child {
      flex: 1 1 auto;
    }
    > .o-filter__drop-paste {
      flex: 0 0 180px;
    }
  }
  &__drop-paste {
    padding: 0 var(--padding);
    box-shadow: 0px -2px 0 0 rgba($color-purple, 0) ;
  }
  &.is-open {
    height: 210px;
    padding: var(--padding);
    padding-bottom: 20px;
    overflow: visible ;
    border-bottom: 1px solid rgba($color-purple, 0.1);
    @include md() {
      height: 250px;
    }
    &::before {
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
      height: 30px;
      left: 0;
      position: absolute;
      top: 101%;
      right: 0px;
      z-index: 1;
    }
  }
  & > .t-flex-component {
    align-items: start;
    gap: 30px;
    height: 100%;
  }

  &__drop-n-copy {
    float: right;
  }
  &__buttons{
    position: absolute;
    right: 20px;
    bottom: 5px;
  }
}
