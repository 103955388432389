.o-remove-bg-bulk-modal {
    padding-top: var(--padding);
    &__check-confirm {
        padding: var(--padding);
        margin-top: 10px;
        display: flex;
        justify-content:left;
        align-items: center;

        input {
            width: 30px;
        }
    }

    &__actions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
