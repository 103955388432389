.m-download-row {
  max-width: 100%;
  svg.--done {
    color: $color-green;
  }
  > div:first-child {
    max-width: 50%;
    flex-shrink: 1;
    > div:first-child {
      max-width: 100%;
    }
  }
  > div:last-child {
    max-width: 50%;
    > div {
      justify-items: flex-end;
    }
  }
  &--generated-missing {
    background: #f0f0f0;
    * {
      color: var(--color-dark-gray);
    }
  }
  &--hidden{
    display: none;
  }
  &__processing {
    color: #9e9e9e;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__copy {
    position: relative;
    &-text{
      position: absolute;
      border-radius: var(--border-radius);
      top: 10%;
      left: -25px;
      background: var(--color-purple);
      color: var(--color-white);
      padding: 4px;
    }
    &-input {
      position: absolute;
      left: 0;
      color: transparent;
      max-width: 300px;
      display: block;
      height: 20px;
      pointer-events: none;
      background: transparent;
      z-index: -200;
      &::selection {
        background: transparent;
        color: transparent;
      }
    }
  }
  &__date {
    font-size: 1.3rem !important;
    letter-spacing: -1px;
  }
  &__download {
    &-title {
      font-size: 1.7rem;
      overflow: hidden;
      max-width: 400px;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    
    @media screen and (max-width: 1200px) {
      &-title { max-width: 6ch; }
    }
    @media screen and (min-width: 1201px) and (max-width: 1500px) {
      &-title { max-width: 12ch; }
    }
    @media screen and (min-width: 1501px) and (max-width: 1800px) {
      &-title { max-width: 16ch; }
    }
    @media screen and (min-width: 1801px) and (max-width: 2100px){
      &-title { max-width: 20ch; }
    }
   
  }
}
