.a-count-number {
  justify-self: center;
  color: var(--color-purple);
  font-size: 2.5rem;
  font-weight: 500;
  margin-right: 10px;
  display: block;
  width: max-content;
  text-align: center;
  &--gray {
    color: var(--color-dark-gray);
  }
  &--red {
    color: var(--color-red);
  }
}
