.t-images-wrapper {
  padding: 0 20px;
  display: grid;
  grid-gap: 10px;
  &--square {
    grid-template-columns: repeat(auto-fit, minmax(17rem, 18rem));
    grid-auto-rows: 17rem;
    .m-image-card--portrait {
      grid-row: span 2;
      img {
        object-fit: cover;
      }
    }
  }
  &--landscape {
    grid-template-columns: repeat(auto-fit, minmax(28rem, 0.5fr));
    grid-auto-rows: 20rem;
  }
  &--portrait {
    grid-template-columns: repeat(auto-fit, minmax(13rem, .3fr));
    grid-auto-rows: 16rem;
    img {
      object-fit: cover;
    }
  }
}
