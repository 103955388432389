.o-previous-next {
  &__info {
    font-size: 1.7rem;
    font-weight: 400;
    &-current {
      color: var(--color-purple);
      font-weight: 600;
    }
  }
}
